import {applyMiddleware, combineReducers, createStore, compose} from "redux";
import thunkMiddleware from "redux-thunk";

import homePageReducer from './reducers/homePageReducer';
import popupReducer from "./reducers/popupReducer/popupReducer";
import blogPageReducer from "./reducers/blogPageReducer";
import shopPageReducer from "./reducers/shopPageReducer";
import cartReducer from "./reducers/cartReducer/cartReducer";
import careerReducer from "./reducers/careerReducer";
import dynamicPageReducer from "./reducers/dynamicPageReducer";
import appReducer from "./reducers/appReducer";

const reducers = combineReducers({
    homePage: homePageReducer,
    blogPage: blogPageReducer,
    popup: popupReducer,
    shop: shopPageReducer,
    cart: cartReducer,
    career: careerReducer,
    dynamicPage: dynamicPageReducer,
    app: appReducer,
});

// @ts-ignore
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunkMiddleware)
    )
);
// @ts-expect-error
window.store = store;

export default store;

// types

// this is dispatch type for this current app generated based on the store
export type AppDispatch = typeof store.dispatch