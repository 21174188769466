import s from "./Cart.module.css";
import React from "react";
import styled from 'styled-components';

const CartTitle = props => {
    return (
    <TitleWrp className={`d-flex align-items-center justify-content-between ${s.titleWrp}`}>
        <h5 className='title'>{props.text}</h5>
        <i className='fas fa-times closeBtn'
           onClick={props.onCartCloseBtnClicked}></i>
    </TitleWrp>
    )
}

export default CartTitle;

const TitleWrp = styled.div`
    background: ${props => props.theme.background.colors.third};
    padding: 20px;

    .title {
        color: ${props => props.theme.text.colors.primary};
    }

    .closeBtn {
        font-size: 20px;
        color: ${props => props.theme.text.colors.primary};
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:hover{
            opacity: 0.6;
        }
    }
`;