import React, { useState, useEffect } from "react";
import s from './Contact.module.css';
import TitleBanner from "../common/TitleBanner/TitleBanner";
import { Container } from "react-bootstrap";
import withStr from "../../hoc/withStr";
import { connect } from "react-redux";
import Branch from "./Branch";
import { imagePrepath, pageImageNames, getStrHelper } from "@kovzydev/module_helpers";
import getApiManager from '@kovzydev/kovzyapi';
import bannerPlaceholder from "../../assets/images/banner1.jpg";
import Loader from "../common/Loader/Loader";
import {ThemeProvider} from 'styled-components'


const Contact = ({
    general
}) => {
    // state
    const [branches, setBranches] = useState([]);
    // .

    // useEffect
    useEffect(() => {
        getApiManager().getBranches().promise
            .then(res => {
                setBranches(res.data);
            })
            .catch(console.error)
    }, [])
    // .

    // variables
    const imageName = general[pageImageNames.contact];
    const bannerImagePath = imageName ? imagePrepath(imageName) : bannerPlaceholder;
    // .

    if(branches?.length && branches.length === 0){
        return <Loader />;
    }

    return (
        <ThemeProvider theme={window.THEME_CONFIG}>
            <div>
                <TitleBanner imageURL={bannerImagePath} text={getStrHelper('nav_contact')} />
                <Container className={s.wrp}>

                    {branches.map(el => (
                        <Branch
                            key={el.id}
                            latitude={el.latitude}
                            longitude={el.longitude}
                            address={el.address}
                            phone={el.phone}
                            general={general}
                        />
                    ))}

                </Container>
            </div>
        </ThemeProvider>
    )
}

const mapStateToProps = (state) => ({
    general: state.app.generalData,
});

export default connect(mapStateToProps, null)(withStr(Contact));