import React, { Component, createRef } from "react";
import Swiper from 'swiper';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import withStr from "../../../hoc/withStr";
import styled, { ThemeProvider } from 'styled-components';
import cx from 'classnames';

class CategorySlider extends Component {
    swiperRef = createRef();

    state = {
        slides: [],
        activeIndex: 0,
    }

    componentWillUnmount() {
        this.setState({
            slides: [],
            activeIndex: 0,
        });

        if (this.props.categories.length > 0 && this.swiperInstance) {
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.categories !== this.props.categories && this.props.categories.length > 0) {
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                slidesPerView: 'auto',
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            const slidesAmount = this.props.categories.length;
            const startSlide = Math.ceil(slidesAmount % 2 ? slidesAmount/2 - 1 : (slidesAmount)/2 - 1);
            this.swiperInstance.slideTo(startSlide);
            

            this.swiperInstance.on('slideChange', () => {
                this.setState({
                    activeIndex: this.swiperInstance.activeIndex
                })
            })

            document.querySelector('.swiper-button-next').addEventListener('click', () => {
                this.swiperInstance.slideNext();
            })

            document.querySelector('.swiper-button-prev').addEventListener('click', () => {
                this.swiperInstance.slidePrev();
            });
        }
    }

    categoriesToSlidesData = () => {
        return this.props.categories.map(cat => ({
            id: cat.id,
            imgLink: cat.image_name,
            name: cat.name,
        }));
    }

    render() {
        const slidesArr = this.props.categories ? this.categoriesToSlidesData() : [];


        const slidesJSX = () => {
            if (slidesArr.length > 0) {
                return slidesArr.map((el, i) => {
                    const active = this.props.chosenCatId === el.id;
                    const handleCategoryClick = () => {
                        if(this.props?.onCategoryClicked && typeof this.props.onCategoryClicked === 'function'){
                            this.props.onCategoryClicked(el.id);
                        }
                    }
                    return (
                        <Link
                            key={i}
                            onClick={handleCategoryClick}
                            to={`/shop?category=${el.name}&page=0`}
                            className='swiper-slide swiperSlideCustom'
                        >
                            <CategorySliderBtn
                                className={cx
                                    ([
                                        'headline-font',
                                        'btn-border-rounded',
                                        'border-none',
                                        'px-4 py-2point5',
                                        {active}
                                    ])
                                }
                            >
                                {el.name}
                            </CategorySliderBtn>
                        </Link>
                    )

                }
                )
            }
            return null;
        }

        return (
            <ThemeProvider theme={window.THEME_CONFIG}>
                <ContainerStyled>
                    <Wrp>
                        <h1 className='title text-center'>{this.props.getStr('categories')}</h1>
                        <SwiperContainer ref={this.swiperRef} className='swiper-container'>
                            <div className="swiper-wrapper">
                                {slidesJSX()}
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </SwiperContainer>
                    </Wrp>
                </ContainerStyled>
            </ThemeProvider>
        )
    }
}

export default withStr(CategorySlider);

const ContainerStyled = styled(Container)`
    .title{
        color:${props => props.theme.text.colors.primary};
    }
`;

const Wrp = styled.div`
    padding: 20px 0;
    margin-top: 10px;
    @media(min-width: 992px){
        margin: 10px 0;
    }
`;

const SwiperContainer = styled.div`
    margin-top: 30px;
    padding-left: 0px;
    padding-right: 0px;

    @media(min-width: 992px){
        padding-left: 40px;
        padding-right: 40px;
    }

    .sliderInnder{
        width: 100%;
    }

    .swiperSlideCustom{
        width: auto;
    }

    .swiper-button-next, .swiper-button-prev{
        color: ${props => props.theme.text.colors.primary};
        @media(max-width: 992px){
            display: none;
        }
    }

    .swiper-button-next{
        right: 0px;
    }

    .swiper-button-prev{
        left: 0px;
    }
`;

const CategorySliderBtn = styled.button`
    color: ${props => props.theme.button.categorySliderButton.colors.normalText};
    background: ${props => props.theme.button.categorySliderButton.colors.normalBg};
    box-shadow: 0px 0px 20px -4px rgb(0 0 0 / 20%);
    transition: all .2s ease;
    margin: 5px;

    &.active{
        color: ${props => props.theme.button.categorySliderButton.colors.hoverText};
        background: ${props => props.theme.button.categorySliderButton.colors.hoverBg};
    }

    &:hover{
        color: ${props => props.theme.button.categorySliderButton.colors.hoverText};
        background: ${props => props.theme.button.categorySliderButton.colors.hoverBg};
    }

    @media(min-width: 992px){
        margin: 10px;
    }
`;