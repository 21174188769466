import { ActionConstants } from "./cartActionCreators"

//initial state
const initState = {
   cart: null,
   couponData: null
   /*
   {
      priceDrop:
   }
   */
}

//reducer
const cartReducer = (state = initState, action) => {
   switch (action.type) {
      case ActionConstants.GET_CART:
         return {
            ...state,
            cart: action.cartData,
         }
      case ActionConstants.CLEAR_CART:
         return {
            ...state,
            cart: [],
         }
      case ActionConstants.SET_COUPON:
         return {
            ...state,
            couponData: {
               discount: action.discount,
               code: action.code
            }
         }
      case ActionConstants.SET_COUPON_ERROR:
         return {
            ...state,
            couponData: {
               error: action.error
            }
         }
      case ActionConstants.REMOVE_COUPON:
         return {
            ...state,
            couponData: null
         }
      default:
         return state;
   }
}

export default cartReducer;









