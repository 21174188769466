import React, {Component, createRef} from "react";
import {Col, Row} from "react-bootstrap";
import moduleStyled from './CheckoutFormInputs.module.css';
import * as PropTypes from "prop-types";
import Radio from "../../../common/Radio/Radio";
import DatePicker from "react-datepicker";
import TimeKeeper from 'react-timekeeper';
import CheckoutMap from "../../CheckoutMap/CheckoutMap";
import { getStrHelper } from "@kovzydev/module_helpers";
import { connect } from 'react-redux';

import cx from 'classnames';
import styled from 'styled-components';
import { BaseBtnContainer } from "@kovzydev/module_commoncomponents";
import { CommonPopup } from "@kovzydev/module_commoncomponents";


class CheckoutFormInputs extends Component {
   state = {
      showMinPricePopup: false,
   }

   timePickerRef = createRef();

   

   render(){
      let minOrderPrice = null;
      if(this.props.generalData?.minimum_order_requirement){
         minOrderPrice = parseFloat(this.props.generalData.minimum_order_requirement);
      }

      let {
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         setFieldValue
      } = this.props;

      const inputTopJSX = (label, name) => {
         return (
            <>
               <label htmlFor={name} className='input-label' >{label}:</label>
               {errors[name] && touched[name]
                  ? <div className='input-error'>{errors[name] && touched[name] && errors[name]}</div>
                  : null
               }
            </>
         )
      }

      const renderMinPricePopup = () => {
         if(!this.state.showMinPricePopup) return null;

         return (
            <CommonPopup
               hidePopup={() => {
                  this.setState({
                     showMinPricePopup: false
                  })
               }}
            >
                <h4 className='text-center mb-3'>{getStrHelper('min_order.title')}</h4>
                <p className='text-center'>{getStrHelper('min_order.text')}</p>
                <hr />
                <p className='text-center'>{`${getStrHelper('min_order.message')} ${minOrderPrice.toFixed(2)} GEL`}</p>
            </CommonPopup>
         )
      }

      const renderMinOrderPriceWarning = () => {
         if(minOrderPrice){
            return <p className={'text-danger mt-3 font-weight-bold'}>{`${getStrHelper('min_order.message')} ${minOrderPrice.toFixed(2)} GEL`}</p>
         }
      }

      const renderSubmitBtn = () => {
         let type = 'button';
         let onClicked = null;
         if(minOrderPrice <= this.props.countCartTotalWithCoupon() || !minOrderPrice){
            type = 'submit';
         } else {
            onClicked = () => {
               this.setState({
                  showMinPricePopup: true,
               });
            }
         }

         return (
            <Col xs={12} className={moduleStyled.btnWrp}>
               <BaseBtnContainer
                  className={cx(['px-4 py-2point5 headline-font submit-btn', { disabled: isSubmitting }])}
                  btnType='primary'
                  size='lg'
                  type={type}
                  onClicked={onClicked}
               >
                  {getStrHelper('submit_order')}
               </BaseBtnContainer>
               {renderMinOrderPriceWarning()}
               <p className={'text-danger mt-3 font-weight-bold'}>{this.props.generalData.order_page_alert}</p>
            </Col>
         )
      }



      const renderTextInput = ({
         label,
         name,
         value,
         isTextarea,
         bootstrapSizeConfig = {xl: 6},
         callback = null
      }) => {
         const InputComp = isTextarea ? 'textarea' : 'input';

         return <Col {...bootstrapSizeConfig} >
            {inputTopJSX(label, name)}
            <InputComp
               id={name}
               className={`form-control ${moduleStyled.input}`}
               type="text"
               name={name}
               onChange={(e) => {
                  if(callback){
                     try{
                        callback(e, name);
                     } catch (e) {
                        console.error(`error in form input for: ${name}`, e)
                     }
                  } else {
                     handleChange(e);
                  }
               }}
               onBlur={handleBlur}
               value={value}
            />
         </Col>
      }

      const renderSelect = (label, name, value, options, callbackFunc=undefined, bootstrapSizeConfig = {xl: 6}) => {
         
         return <Col {...bootstrapSizeConfig}>
            {inputTopJSX(label, name)}
            <select
               id={name}
               className={`form-control ${moduleStyled.input}`}
               name={name}
               onChange={callbackFunc ?? handleChange}
               onBlur={handleBlur}
               value={value}
            >
               {options.map((opt, i) => {
                  let price = opt.price;
                  return (<option key={opt.id} value={opt.id}>{opt.name} - {price} GEL</option>)
               })}
            </select>
         </Col>
      }

      const renderRadio = (label, name, value, options, bootstrapSizeConfig = {xl: 6}, paymentRadio) => {

         let radioCheckedFunc = (index) => {
            return values[name] === index;
         }

         let radioValueFunc = (index) => {
            return index;
         }

         return <Col xs={12}>
            <Row className={'d-flex justify-content-between'}>
               {options.map( (opt, i) => {
                     return (
                        <Col key={opt.id} {...bootstrapSizeConfig} className={'mb-2'}>
                           <Radio
                              name={opt.name}
                              isChecked={radioCheckedFunc(i)}
                              value={opt.type}
                              onChangeHandler={() => {
                                 setFieldValue(name, radioValueFunc(i))
                              }}
                           />
                        </Col>
                     )
                  }
               )}
            </Row>
         </Col>
      }


      return (
         <FormStyled className='row align-items-end' onSubmit={handleSubmit}>


            <Col xs={12}>
               <div className={`d-flex align-items-center headlineWrp`}>
                  <i className={`fas fa-user h4 mb-0 form-section-icon`}></i>
                  <h3 className='form-section-headline'>{getStrHelper('checkout_general_info')}</h3>
               </div>
            </Col>

            {this.props.serverError
               ? <Col xs={12}>
                  <div className={moduleStyled.serverError}>{this.props.serverError}</div>
               </Col>
               : null
            }


            {renderTextInput({
               label: getStrHelper('first_name'),
               name: 'firstName',
               value: values.first_name,
            })}

            {renderTextInput({
               label: getStrHelper('last_name'),
               name: 'lastName',
               value: values.lastName,
            })}


            


            <div className="col-xl-6 mt-3">

               <div className={'flex-grow-1'}>
                  <label className={'input-label mb-2'}>{getStrHelper('address_field')}</label>
               </div>

               <div className={'d-flex align-items-end'}>
                  <input type="text" className={'form-control'} disabled={true} value={this.props.addressInput}/>

                  <button
                      className={'btn btn-secondary h-100 ml-2'}
                      onClick={() => this.props.showLocationModal(true)}
                      >
                     <div className={`${moduleStyled.locationIcon}`}>
                        <i className="fas fa-map-marker-alt"></i>
                     </div>
                  </button>

               </div>

            </div>


            {/* google map for address selection*/}
            <Col xs={12} className={'mt-2 mb-5'}>
               <CheckoutMap
                  disabled={true}
                  mapMarker={this.props.mapMarker}
                  onMapClicked={() => {}}
                  google={this.props.google}
                  addressInput={this.props.addressInput}
               />
            </Col>
            {/**/}
            
            {renderTextInput({
               label: getStrHelper('phone_field'),
               name: 'phone',
               value: values.phone,
            })}

            {renderTextInput({
               label: getStrHelper('email_field'),
               name: 'email',
               value: values.email,
            })}


            <Col xs={12}>
               <hr className={'mb-0 mt-4'}/>
               <Row>
                  {renderTextInput({
                     label: getStrHelper('comment_field'),
                     name: 'comment',
                     value: values.comment,
                     isTextarea: true
                  })}
               </Row>
            </Col>



            <Col xs={12} className={'mt-5'}>
               <div className={`d-flex align-items-center headlineWrp`}>
                  <i className={`fas fa-truck-loading h4 mb-0 form-section-icon`}></i>
                  <h3 className='form-section-headline'>{getStrHelper('delivery_input_title')}</h3>
               </div>
            </Col>

            {
               renderSelect(getStrHelper('delivery_method_field'), 'deliveryMethod', values.deliveryMethod, this.props.deliveryOptions, (e) => {
                  this.props.onDeliveryChangedHandler(e.target.value, setFieldValue);
               })
            }


            {this.props.showCalendar()
               ? (
               <>
                  <Col sm={6} >
                     <label className={moduleStyled.label} >{getStrHelper('delivery_date_field')}:</label>
                     <DatePicker
                        className={`form-control w-100 ${moduleStyled.datePicker} ${moduleStyled.input}`}
                        selected={this.props.inputDate}
                        onChange={this.props.onInputDateChanged}
                        minDate={new Date()}
                     />
                  </Col>
                  <Col sm={6}>
                     <label htmlFor="deliveryTime" className={moduleStyled.label}>{getStrHelper('delivery_time_field')}</label>
                     <input
                        ref={this.timePickerRef}
                        id={'deliveryTime'}
                        className={`form-control ${moduleStyled.input}`}
                        type="text"
                        name={'deliveryTime'}
                        onFocus={() => {
                           this.props.showTimePickerHandler(true);
                        }}
                        onChange={() => {}}
                        value={this.props.deliveryTime}
                     />
                     {this.props.showTimePicker &&
                        <div className={'position-relative'}>
                           <div className={moduleStyled.timePickerWrp}>
                              <TimeKeeper
                                 time={this.props.deliveryTime}
                                 onChange={(newTime) => this.props.setTime(newTime.formatted24)}
                                 switchToMinuteOnHourSelect
                                 coarseMinutes={5}
                                 forceCoarseMinutes
                                 onDoneClick={() => {
                                    this.props.showTimePickerHandler(false);
                                 }}
                                 hour24Mode
                              />
                           </div>
                        </div>
                     }
                  </Col>
               </>
               )
               : null
            }

            <Col xs={12} className={'mt-5 mb-3'}>
               <div className={`d-flex align-items-center headlineWrp`}>
                  <i className={`fas fa-money-check-alt h4 mb-0 form-section-icon`}></i>
                  <h3 className='form-section-headline'>{getStrHelper('payment_method_input_title')}</h3>
               </div>
            </Col>

            {renderRadio(getStrHelper('payment_method_field'), 'paymentMethod', values.paymentMethod, this.props.paymentOptions, {xs:12})}

            { 
               this.props.checkoutConfigs?.show_tip === '1'
               ? renderTextInput({
                  label: getStrHelper('tip'),
                  name: 'tip',
                  value: values.tip,
                  callback: (e) => this.props.onTipChangedHandler(e.target.value, setFieldValue)
               })
               : null
            }

            {renderSubmitBtn()}
            {renderMinPricePopup()}


         </FormStyled>
      )
   }
}

CheckoutFormInputs.propTypes = {
   values: PropTypes.any,
   errors: PropTypes.any,
   touched: PropTypes.any,
   handleChange: PropTypes.any,
   handleBlur: PropTypes.any,
   handleSubmit: PropTypes.any,
   isSubmitting: PropTypes.any
}

const mapStateToProps = state => ({
   generalData: state.app.generalData,
   cartData: state.cart.cart
});

export default connect(mapStateToProps)(CheckoutFormInputs);

const FormStyled = styled.form`
   .input-error{
      color: #ef2b2b;
   }
   .input-label{
      margin-top: 15px;
      color: ${props => props.theme.text.colors.primary};
   }

   .form-section-headline{
      color: ${props => props.theme.text.colors.primary};
   }

   .form-section-icon{
      width: 40px;
      height: 40px;
      margin-right: 15px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: ${props => props.theme.checkoutPage.headlinIcon.bgColor};
      color: ${props => props.theme.checkoutPage.headlinIcon.textColor};
      border-radius: 50%;
   }

   .headlineWrp{
      padding-bottom: 20px;
      border-bottom: 1px solid ${props => props.theme.text.colors.subtle};
  }
`;