import getApiManager, {Types as ApiTypes} from "@kovzydev/kovzyapi";
import {
    OptionsType,
    ActionCreatorTypes,
    ReducerHelpersTypes,
} from './popupReducerTypes';

import * as ACHelpers from './popupReducerActionCreatorsHelpers';

// ACTION CONSTANTS
const CLOSE_POPUP = 'CLOSE_POPUP';
const SHOW_POPUP = 'SHOW_POPUP';
const SET_OPTIONS = 'SET_OPTIONS';
const ADD_INGREDIENT = 'ADD_INGREDIENT';
const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
const INCREMENT_AMOUNT = 'INCREMENT_AMOUNT';
const DECREMENT_AMOUNT = 'DECREMENT_AMOUNT';
const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS';
const SET_ID = 'SET_ID';

const ActionConst = {
    CLOSE_POPUP,
    SHOW_POPUP,
    SET_OPTIONS,
    ADD_INGREDIENT,
    REMOVE_INGREDIENT,
    INCREMENT_AMOUNT,
    DECREMENT_AMOUNT,
    SET_SELECTED_OPTIONS,
    SET_ID
}

export {
    ActionConst
}
// Action Constants /.


// ACTION CREATORS
export const closePopup = () => {
    return { type: CLOSE_POPUP };
}

export const showPopup = (dataObj : ActionCreatorTypes.ShowPopupAcArgType) => {
    return { type: SHOW_POPUP, ...dataObj };
}

export const setProductPopupId = (id : number) => {
    return {
        type: SET_ID,
        id
    }
}

export const setOptions = (optionsObj : OptionsType) => {
    return { type: SET_OPTIONS, options: optionsObj }
}

// index is used when we select item to edit
export const renderPopup : ActionCreatorTypes.RenderPopup = ({
    prod_id,
}) => {
    return (dispatch) => {
        ACHelpers.renderPopupHelper({
            prod_id,
            dispatch,
            actionsUsed: {
                setOptions,
                showPopup,
                setSelectedOptions
            }
        });
    }
}

export const setSelectedOptions : ActionCreatorTypes.SetSelectedOptions = (selectedOptions) => {
    return { type: SET_SELECTED_OPTIONS, selectedOptions: selectedOptions }
}

export const onAddIngredient = (newOption : ReducerHelpersTypes.newOptionArgType) => {
    
    return { type: ADD_INGREDIENT, newOption }
}

export const onRemoveIngredient = (optionToRemove : ReducerHelpersTypes.newOptionArgType) => {
    return { type: REMOVE_INGREDIENT, optionToRemove }
}

export const incrementAmount = () => {
    return { type: INCREMENT_AMOUNT }
}

export const decrementAmount = () => {
    return { type: DECREMENT_AMOUNT }
}

export const editCartItemPopup : ActionCreatorTypes.EditCartItemPopup = ({
    prod_id,
    index,
    amount,
    selectedOptionsFromCart,
    callback = undefined
}) => {
    return dispatch => {

        getApiManager().getProduct({ Productid: prod_id }).promise
            .then(res => {
                
                const data = res.data;
                ACHelpers.renderPopupHelper({
                    prod_id : data.id,
                    selectedOptions : ACHelpers.reverseTransformSelectedOptions(selectedOptionsFromCart),
                    amount,
                    editMode: true,
                    index,
                    dispatch,
                    actionsUsed: {
                        setOptions,
                        showPopup,
                        setSelectedOptions
                    }
                });

                if (callback) {
                    callback()
                }
            })
    }
}