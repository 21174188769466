import React, {Component} from 'react';
import {connect} from "react-redux";
import Loader from "../components/common/Loader/Loader";

const WithStr = (Comp) => {
    class strComp extends Component {

        getStr = (name) => {
            // get str from loaded strings
            return this.props.strs[name];
        }

        render(){
            if(!this.props.strs || Object.keys(this.props.strs).length === 0){
                return <Loader />
            }

            // if strs from redux are empty -> return loader

            return <Comp getStr={this.getStr} {...this.props}/>
        }
    };

    const mapStateToProps = (state) => ({
        strs: state.app.strings,
        langId: state.app.langId,
    })

    const mapDispatchToProps = (dispatch) => ({
    })

    return connect(mapStateToProps, mapDispatchToProps)(strComp);
};

export default WithStr;