import React from "react";
import {imagePrepath} from "@kovzydev/module_helpers";
import styled from 'styled-components/macro';

const CartItems = props => {

    const cartItemsJSX = () => {
        if (props.cartData) {
            return props.cartData.map((cartItem, i) => {
                const price = Math.round(cartItem.price * 100) / 100;

                // creating customizables string

                let customizablesArr = [];
                if(cartItem.hasOwnProperty('children')){
                    // this is a collection's customizables
                    
                }
                else {
                    // this is a single product's customizables
                    cartItem.customizables.forEach(custom => {
                        custom.packs.forEach(packItem => {
                            customizablesArr.push(`${packItem[`name`]}`);
                        })
                    });
                }

                const customizablesString = customizablesArr.join(', ');
                // /.



                return (
                    <Wrp key={cartItem.id}>
                        <div className='editBtn' onClick={() => {
                            props.onEditCartItem({
                                index: i,
                                amount: cartItem.amount,
                                selectedOptionsFromCart: cartItem,
                                prod_id: cartItem.id
                            });
                        }}>
                            <i className={`fas fa-pencil-alt`} ></i>
                        </div>

                        <div className='removeBtn' onClick={() => props.onCartItemRemoved(i)}>
                            <i className={`fas fa-trash-alt`} ></i>
                        </div>

                        <ImageWrp>
                            <ImageInnerWrp>
                                <img src={imagePrepath(cartItem.image_name)}
                                     alt="product"/>
                            </ImageInnerWrp>
                        </ImageWrp>
                        <div className={'px-3 text-center'}>
                            <ItemName>
                                {cartItem.name}
                                <Amount>
                                    x{cartItem.amount}
                                </Amount>
                            </ItemName>
                            <ItemOptions>{customizablesString.length > 0 ? `+ ${customizablesString}` : ''}</ItemOptions>
                            <div className='d-flex align-items-center justify-content-center mt-2'>
                                <ItemPrice>{price} GEL</ItemPrice>
                            </div>
                        </div>
                    </Wrp>
                )
            })
        }
        return null;
    }

    return (<div className={`w-100`}>
        <div>
        {cartItemsJSX()}
        </div>
    </div>)
}

export default CartItems;

const Wrp = styled.div`
    border-bottom: 1px solid ${props => props.theme.text.colors.subtle};
    padding: 25px;
    position: relative;

    .removeBtn, .editBtn{
        position: absolute;
        top: 10px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
        background: ${props => props.theme.button.primary.colors.normalBg};
        color: ${props => props.theme.button.primary.colors.normalText};
        cursor: pointer;
        transition: all .3s ease;

        &:hover{
            background: ${props => props.theme.button.primary.colors.hoverBg};
            color: ${props => props.theme.button.primary.colors.hoverText}
        }
    }
    .removeBtn{
        right: 10px;
    }
    .editBtn{
        left: 10px;
    }
`;

const Amount = styled.div`
    color: ${props => props.theme.cartSidebar.amount.textColor};
    background: ${props => props.theme.cartSidebar.amount.bgColor};
    box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);

    font-weight: normal;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
`;

const ItemName = styled.div`
display: flex;
align-items: center;
justify-content: center;
color: ${props => props.theme.text.colors.primary};
font-weight: bold;
text-align: left;
margin-top: 20px;
margin-bottom: 20px;
`;

const ItemOptions = styled.p`
color: ${props => props.theme.text.colors.secondary};
font-size: 0.9rem;
`;

const ItemPrice = styled.p`
font-size: 0.95rem;
color: ${props => props.theme.text.colors.secondary};
`;

const ImageWrp = styled.div`
width: 150px;
margin: 0 auto;
img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    position: absolute;
    top: 0;
    left: 0;
}

@media (min-width: 992px) {
    width: 130px;
}
`;

const ImageInnerWrp = styled.div`
position: relative;
    width: 100%;
    padding-top: 100%;
`;