import s from "./Cart.module.css";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import styled from 'styled-components';
import { getStrHelper } from '@kovzydev/module_helpers';

const CartSummary = props => {
    if(!props.cartData){
        return null;
    }

    if(props.cartData.length === 0){
        return (
           <ContainerStyled fluid className='py-4 text'>
               <div className={'text-center'}>{getStrHelper('cart_empty')}</div>
           </ContainerStyled>
        )
    }

    const renderDiscountPrice = (discount) => {
        if(!discount){
            return null;
        }

        return(
            <>
                <Row>
                    <SummaryLeft xs={7}>{getStrHelper('order_sale') || 'ფასდაკლება'}:</SummaryLeft>
                    <SummaryRight xs={5}>
                        - {discount.toFixed(2)} GEL
                    </SummaryRight>
                </Row>
                <hr/>
            </>
        )
    }
    
    const isNumberStr = (value) => {
        return value && !isNaN(value)
    }

    const renderTip = () => {
        if(isNumberStr(props?.tip)){
            const tipValue = parseFloat(props.tip);
            if(tipValue >= 0.1){
                return (
                    <Row className={'mb-1'}>
                        <SummaryLeft xs={7}>{getStrHelper('tip')}:</SummaryLeft>
                        <SummaryRight xs={5}>{tipValue?.toFixed('2')} GEL</SummaryRight>
                    </Row>
                )
            }
        }
        return null;
    }

    const getTipValue = () => {
        let tipValue = 0;
        if(isNumberStr(props?.tip)){
            try {
                tipValue = parseFloat(props?.tip);
                tipValue = tipValue?.toFixed('2');
                tipValue = parseFloat(tipValue);
            } catch (e) {
                console.error('tip value couldn\'t be converted to float: ', e);
                tipValue = 0
            }
        }
        return tipValue;
    }

    const getTotalServicesFeesPrice = (finalPrice) => {
        let totalServicesFeesPrice = 0;
        if(Array.isArray(props.additionalFeesInfo) && props.additionalFeesInfo.length > 0){
            totalServicesFeesPrice = props.additionalFeesInfo.reduce((prev, cur) => {
                if(cur){
                    if(cur?.type === 1){
                        return prev + parseFloat(cur.amount) * 100;
                    } else if(cur?.type === 0){
                        return prev + parseFloat(finalPrice * (parseFloat(cur.amount) / 100).toFixed(2)) * 100;
                    }
                }
            }, 0);
    
            totalServicesFeesPrice = totalServicesFeesPrice / 100;
        }
        return totalServicesFeesPrice;
    }

    let orderTotalPrice = 0;
    let finalPrice = 0;
    let oldPrice = 0;

    orderTotalPrice = props.cartData.reduce((accum, cur) => {
        return accum + cur.price;
    }, 0);
    orderTotalPrice = Math.round(orderTotalPrice*100)/100;

    let orderFinalJSX = null;
    if(props.deliveryPrice || props.deliveryPrice === 0) {
        const discount = props?.couponData?.discount ? props.couponData.discount*100 : null;
        const tipValue = getTipValue();

        oldPrice = Math.round( (orderTotalPrice + getTotalServicesFeesPrice(orderTotalPrice))*100 + props.deliveryPrice*100)/100

        finalPrice = (oldPrice * 100 - discount + tipValue * 100) / 100;
        finalPrice = parseFloat(finalPrice.toFixed(2));
        orderFinalJSX = (
            <>
                <Row className={'mb-1'}>
                    <SummaryLeft xs={7}>{getStrHelper('delivery_price')}:</SummaryLeft>
                    <SummaryRight xs={5}>{props.deliveryPrice?.toFixed('2')} GEL</SummaryRight>
                </Row>
                {renderTip()}
                
                <hr/>
                {renderDiscountPrice(discount/100)}
                <Row>
                    <SummaryLeft xs={7} className='summaryLeftLg'>{getStrHelper('order_total')}:</SummaryLeft>
                    <SummaryRight xs={5} className='summaryRightLg'>
                        <small class="d-block text-secondary mx-1 line-through">
                            {finalPrice !== (oldPrice + tipValue) ? oldPrice + 'GEL' : ''}
                        </small>
                        {finalPrice} GEL
                    </SummaryRight>
                </Row>
            </>
        );
    }

    


    const renderFees = () => {
        if(Array.isArray(props.additionalFeesInfo) && props.additionalFeesInfo.length > 0){
            return props.additionalFeesInfo?.map((el) => {
                let amount = el.amount;
                if(el?.type === 0){
                    amount = parseFloat(orderTotalPrice * (parseFloat(el.amount) / 100).toFixed(2));
                    amount = amount.toFixed(2);
                }

                return (
                    <Row className={'mb-1'}>
                        <SummaryLeft xs={7}>{ el.value }:</SummaryLeft>
                        <SummaryRight xs={5}>{ amount } GEL</SummaryRight>
                    </Row>
                )
            })
        }

        return null;
    }

    return (
    <Summary fluid>
        <Row className={'mb-1'}>
            <SummaryLeft xs={7}>{getStrHelper('order_price')}:</SummaryLeft>
            <SummaryRight xs={5}>{orderTotalPrice.toFixed('2')} GEL</SummaryRight>
        </Row>

        { renderFees() }
        
        {orderFinalJSX}

    </Summary>
    )
}

export default CartSummary;

const ContainerStyled = styled(Container)`
    color: ${props => props.theme.text.colors.primary};
`

const Summary = styled(Container)`
    padding: 20px;
    font-size: 0.95rem;
`;

const SummaryLeft = styled(Col)`
    color: ${props => props.theme.text.colors.primary};
    text-align: right;

    &.summaryLeftLg{
        font-size: 1.3rem;
    }
`;

const SummaryRight = styled(Col)`
    color: ${props => props.theme.text.colors.primary};
    font-weight: bold;
    text-align: left;

    &.summaryRightLg{
        font-size: 1.3rem;
    }
`;