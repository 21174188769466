//action constants
import apiHandler from "../../api/api";

const SET_PRODUCTS = 'SET_PRODUCTS';
const APPEND_PRODUCTS = 'APPEND_PRODUCTS';
const SET_CATEGORIES = 'SET_CATEGORIES';

//initial state
const initState = {
   categories: [],
   products: [],
   minPriceEdge: undefined,
   maxPriceEdge: undefined,
   count: 0,
}

// ** may change
export const PRODUCTS_LIMIT = 6;
// ** may change

//reducer
const shopPageReducer = (state = initState, action) => {
   switch (action.type) {
      case SET_PRODUCTS:
         // debugger;
         return {
            ...state,
            products: action.products,
            minPriceEdge: action.minPriceEdge,
            maxPriceEdge: action.maxPriceEdge,
            count: action.count,
         }
      case APPEND_PRODUCTS:
         return {
            ...state,
            products: [...state.products, ...action.appendedProducts]
         }
      case SET_CATEGORIES:
         return {
            ...state,
            categories: action.categories
         }
      default:
         return state;
   }
}

export default shopPageReducer;


//action creators
const createProductFiltersObject = (dataObj, page) => {
   const {search, sort, minPrice, maxPrice} = dataObj;
   let catId = null;
   if(dataObj?.catId){
      catId = dataObj.catId
   }

   let priceObj = {}
   if(minPrice && maxPrice){
      priceObj = {
         min: minPrice,
         max: maxPrice
      };
   }

   const reqObj = {
      SearchPhrase: search,
      Order: {
         price: sort,
      },
      Filters: {
         price: priceObj,
      },
      Pagination: {
         limit: PRODUCTS_LIMIT,
         page: page,
      }
   }

   if(catId){
      reqObj.Categoryid = catId;
   }

   return reqObj
}

export const appendProducts = (dataObj, page) => {
   return dispatch => {
      return apiHandler('getProducts', createProductFiltersObject(dataObj, page))
         .then(res => {
            dispatch({
               type: APPEND_PRODUCTS,
               appendedProducts: res.Products ?? [],
               minPriceEdge: res.Min_Price ? parseFloat(res.Min_Price) : undefined,
               maxPriceEdge: res.Max_Price ? parseFloat(res.Max_Price) : undefined,
               count: res.Count ?? 0,
            });
            return res.Products ?? []
         });
   }
}

export const getProducts = (dataObj, page) => {
   return dispatch => {
      return apiHandler('getProducts', createProductFiltersObject(dataObj, page))
         .then(res => {
            dispatch({
               type: SET_PRODUCTS,
               products: res.Products ?? [],
               minPriceEdge: res.Min_Price ? parseFloat(res.Min_Price) : undefined,
               maxPriceEdge: res.Max_Price ? parseFloat(res.Max_Price) : undefined,
               count: res.Count ?? 0,
            })
         });
   }
}

export const appendProductsByCategory = (dataObj, page) => {
   return dispatch => {
      return apiHandler('getProductsByCategory', createProductFiltersObject(dataObj, page))
         .then(res => {
            dispatch({
               type: APPEND_PRODUCTS,
               appendedProducts: res.Products ?? [],
               minPriceEdge: res.Min_Price ? parseFloat(res.Min_Price) : undefined,
               maxPriceEdge: res.Max_Price ? parseFloat(res.Max_Price) : undefined,
               count: res.Count ?? 0,
            });
            return res.Products ?? [];
         });
   }
}

export const getProductsByCategory = (dataObj, page) => {
   return dispatch => {
      return apiHandler('getProductsByCategory', createProductFiltersObject(dataObj, page))
         .then(res => {
            dispatch({
               type: SET_PRODUCTS,
               products: res.Products ? res.Products : [],
               minPriceEdge: res.Min_Price ? parseFloat(res.Min_Price) : undefined,
               maxPriceEdge: res.Max_Price ? parseFloat(res.Max_Price) : undefined,
               count: res.Count ?? 0,
            })
         });
   }
}

export const getCategories = () => {
   return dispatch => {
      return apiHandler('getCategories')
         .then(res => {

            const mappedCategories = res.map(el => ({
               id: el.id,
               name: el.name,
               image_name: el.image_name,
            }));

            dispatch({
               type: SET_CATEGORIES,
               categories: mappedCategories
            });

            return mappedCategories;
         });
   }
}



