import React from 'react';
import s from './PageNotFound.module.css';

const PageNotFound = (props) => {
   return (
      <div className={s.wrp}>
         <h3>Sorry, Page Not Found</h3>
      </div>
   );
};

export default PageNotFound;