import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { BaseBtnContainer } from '@kovzydev/module_commoncomponents';
import { getLogoAddress, linkWithLang, getStrHelper } from "@kovzydev/module_helpers";
import { Dropdown } from "react-bootstrap";
import { getRegistrationData } from '@kovzydev/module_manager';
import styled from 'styled-components';

const MobileNavbarLinks = ({
    languages,
    langId,
    chooseLangHandler,
    onSidebarCloseBtnClicked
}) => {
    const chosenLanguage = languages.find(el => el.id === langId).name;
    const otherLanguages = languages.map((el, i) => {
        if (el.id !== langId) {
            return <div key={i} className={'dropdown-item'} onClick={() => chooseLangHandler(el.id)}>
                {el.name}
            </div>
        }
        return null;
    })

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <LanguageDropdownToggle className='d-block text-center py-2 headline-font'
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </LanguageDropdownToggle>
    ));

    return (
        <div>
            <NavItem>
                <Link
                    onClick={onSidebarCloseBtnClicked}
                    to={linkWithLang('/')}
                >
                    {getStrHelper('nav_home')}
                </Link>
            </NavItem>

            {getRegistrationData('getNavigations').map((navigationState, i) => {
                const nav = navigationState();
                return nav.map(({ to, label }, j) => {
                    return (
                        <NavItem>
                            <Link
                                key={`${i}_${j}`}
                                onClick={onSidebarCloseBtnClicked}
                                to={to}
                            >
                                {label}
                            </Link>
                        </NavItem>
                    );
                });
            })}

            <NavItem>
                <Link
                    onClick={onSidebarCloseBtnClicked}
                    to={linkWithLang('/contact')}
                >
                    {getStrHelper('nav_contact')}
                </Link>
            </NavItem>


            <NavItemMargin>
                <BaseBtnContainer
                    onClicked={() => onSidebarCloseBtnClicked}
                    btnType='primary'
                    linkPath={linkWithLang('/categories')}
                    className='w-100 py-2point5 headline-font border-none'
                    rounded
                >
                    {getStrHelper('nav_shop')}
                </BaseBtnContainer>
            </NavItemMargin>

            <NavItemMargin>
                <Dropdown >
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                        {chosenLanguage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'w-100'}>
                        {otherLanguages}
                    </Dropdown.Menu>
                </Dropdown>
            </NavItemMargin>
        </div>
    )
}

// navitems
const NavItem = styled.div`
    display: block;
    margin: 15px 30px 0;
    padding-bottom: 6px;
    border-bottom: 1px solid #b6b6b6;
    color: ${props => props.theme.text.colors.primary};
    transition: color 0.2s ease;
`

const NavItemMargin = styled.div`
    margin: 15px 30px 0;
`
// .

// langauge dropdown
const LanguageDropdownToggle = styled.div`
    color: ${props => props.theme.text.colors.primary};
    border: 1px solid ${props => props.theme.text.colors.primary};
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
`;
// /.














class MobileNavbarSidebarComp extends Component {
    sidebarWrpRef = createRef();
    sidebarRef = createRef();

    componentDidMount() {
        const sidebarWrpRef = this.sidebarWrpRef.current;
        const sidebarRef = this.sidebarRef.current;

        gsap.set(sidebarWrpRef, { display: 'none', opacity: '0' });
        gsap.set(sidebarRef, { x: '-100%' });
    }

    componentDidUpdate(prevProps) {
        const sidebarWrpRef = this.sidebarWrpRef.current;
        const sidebarRef = this.sidebarRef.current;
        if (this.props.showSidebar !== prevProps.showSidebar) {
            if (this.props.showSidebar) {
                // show wrapper
                gsap.set(sidebarWrpRef, { display: 'block' });
                gsap.to(sidebarWrpRef, { opacity: '1', duration: 0.3 });
                // slide in sidebar
                gsap.to(sidebarRef, { x: 0, duration: 0.3 });
            } else {
                // slide in sidebar
                gsap.to(sidebarRef, {
                    x: '-100%', duration: 0.3, onComplete: () => {
                        // show wrapper
                        gsap.to(sidebarWrpRef, {
                            opacity: '0', duration: 0.3, onComplete: () => {
                                gsap.set(sidebarWrpRef, { display: 'none' });
                            }
                        });
                    }
                });
            }
        }
    }

    render() {


        const modulesRegistrationData = getRegistrationData('navigation');

        return (
            <MobileNavbarSidebarWrp ref={this.sidebarWrpRef}>
                <SidebarDarkOverlay onClick={this.props.onSidebarCloseBtnClicked} />

                <MobileNavbarSidebar ref={this.sidebarRef}>

                    <SidebarCloseBtn
                        className='fas fa-times'
                        onClick={this.props.onSidebarCloseBtnClicked}
                    />

                    <SidebarLogoWrp>
                        <SidebarLogo src={getLogoAddress(this.props.generalData)} alt="navbar-logo" />
                    </SidebarLogoWrp>

                    {/* links */}
                    <MobileNavbarLinks
                        languages={this.props.languages}
                        langId={this.props.langId}
                        chooseLangHandler={this.props.chooseLangHandler}
                        onSidebarCloseBtnClicked={this.props.onSidebarCloseBtnClicked}
                    />
                    {/* /. */}
                </MobileNavbarSidebar>
            </MobileNavbarSidebarWrp>
        )
    }
}

export default MobileNavbarSidebarComp;

const MobileNavbarSidebarWrp = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
`;

const SidebarDarkOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
`;

const MobileNavbarSidebar = styled.div`
    width: 270px;
    height: 100%;
    background: ${props => props.theme.background.colors.primary};
`;

// sidebar close btn
const SidebarCloseBtn = styled.i`
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 20px;
    color: ${props => props.theme.text.colors.primary};
    cursor: pointer;
    transition: opacity 0.3s ease;
    z-index: 3;

    &:hover{
        opacity: 0.6;
    }
`
// /.


// sidebar logo
const SidebarLogoWrp = styled.div`
    border-bottom: 1px solid #b6b6b6;
    padding-top: 15px ;
    padding-bottom: 15px ;
`;

const SidebarLogo = styled.img`
    width: 30%;
    display: block;
    margin: auto;
`;
// .