import React, { Component, createRef } from "react";
import CartTitle from "./CartTitle";
import CartItems from "./CartItems";
import CartSummary from "./CartSummary";

import { gsap } from 'gsap';
import { getStrHelper, linkWithLang } from "@kovzydev/module_helpers";
import { BaseBtnContainer } from '@kovzydev/module_commoncomponents';
import { Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import cx from 'classnames';

class Cart extends Component {

    outerWrpRef = createRef();
    innerWrpRef = createRef();

    componentDidMount() {
        const outerWrpRef = this.outerWrpRef.current;
        const innerWrpRef = this.innerWrpRef.current;

        gsap.set(outerWrpRef, { display: 'none', opacity: '0' });
        gsap.set(innerWrpRef, { x: '100%' });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const outerWrpRef = this.outerWrpRef.current;
        const innerWrpRef = this.innerWrpRef.current;
        if (this.props.showCart !== prevProps.showCart) {
            if (this.props.showCart) {
                // show wrapper
                gsap.set(outerWrpRef, { display: 'block' });
                gsap.to(outerWrpRef, { opacity: '1', duration: 0.3 });
                // slide in sidebar
                gsap.to(innerWrpRef, { x: 0, duration: 0.3 });
            } else {
                // slide in sidebar
                gsap.to(innerWrpRef, {
                    x: '100%', duration: 0.3, onComplete: () => {
                        // show wrapper
                        gsap.to(outerWrpRef, {
                            opacity: '0', duration: 0.3, onComplete: () => {
                                gsap.set(outerWrpRef, { display: 'none' });
                            }
                        });
                    }
                });
            }
        }
    }

    finishOrderBtn = () => {
        if (this.props.cartData?.length > 0) {
            return (
                <Link
                    onClick={this.props.onCartCloseBtnClicked}
                    to={linkWithLang('/checkout')}
                >
                    <button
                        className={cx(
                            'checkoutBtn',
                            'font-weight-bold headline-font',
                            'py-3'
                        )}
                    >
                        {getStrHelper('checkout')}
                    </button>
                </Link>
            )
        }
        return null;
    }

    render() {
        return (
            <ThemeProvider theme={window.THEME_CONFIG}>
                <OuterWrp ref={this.outerWrpRef}>

                    <div onClick={this.props.onCartCloseBtnClicked} className='cartDarkOverlay' />

                    <InnerWrp ref={this.innerWrpRef}>
                        <InnerScroll>

                            <CartTitle
                                onCartCloseBtnClicked={this.props.onCartCloseBtnClicked}
                                text={this.props.getStr('your_cart')}
                            />

                            <CartItems
                                onEditCartItem={this.props.onEditCartItem}
                                onCartItemRemoved={this.props.onCartItemRemoved}
                                cartData={this.props.cartData}
                            />

                            <CartSummary
                                cartData={this.props.cartData}
                            />

                        </InnerScroll>

                        {this.finishOrderBtn()}

                    </InnerWrp>
                </OuterWrp>
            </ThemeProvider>
        )
    }
}

export default Cart;

const OuterWrp = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    .cartDarkOverlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
    }

    .checkoutBtn{
        width: 100%;
        font-size: 1.3rem;

        position: absolute !important;
        left: 0;
        bottom: 0;

        color: ${props => props.theme.cartSidebar.checkoutBtn.colors.normalText};
        background: ${props => props.theme.cartSidebar.checkoutBtn.colors.normalBg};
        border: none;
        box-shadow: 0px 0px 20px -4px rgb(0 0 0 / 20%);
        transition: all .3s ease;
        &:hover{
            color: ${props => props.theme.cartSidebar.checkoutBtn.colors.hoverText};
            background: ${props => props.theme.cartSidebar.checkoutBtn.colors.hoverBg};
        }
    }
`;

const InnerWrp = styled.div`
    max-width: 100%;
    height: 100%;
    background: ${props => props.theme.background.colors.secondary};
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 360px;
    }
`;

const InnerScroll = styled.div`
    overflow: auto;
    height: 100%;
    padding-bottom: 100px;
`;