import React, {useEffect} from "react";

const Messenger = ({
    page_id
}) => {
    useEffect(() => {
        if(page_id){
            const chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", page_id);
            chatbox.setAttribute("attribution", "biz_inbox");
    
            window.fbAsyncInit = function() {
                window.FB.init({
                xfbml            : true,
                version          : 'v12.0'
                });
            };
    
            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }

    }, [page_id]);

    return (
        <React.Fragment>
            <div id="fb-root" />
            <div id="fb-customer-chat" class="fb-customerchat" />
        </React.Fragment>
    )
}

export default Messenger