import getApiManager from '@kovzydev/kovzyapi';

//action constants
const GET_CART = 'GET_CART';
const CLEAR_CART = 'CLEAR_CART';
const SET_COUPON = 'SET_COUPON';
const REMOVE_COUPON = 'REMOVE_COUPON';
const SET_COUPON_ERROR = 'SET_COUPON_ERROR';

export const ActionConstants = {
   GET_CART,
   CLEAR_CART,
   SET_COUPON,
   REMOVE_COUPON,
   SET_COUPON_ERROR
};

//action creators
const setCart = (cartData, dispatch) => {
   dispatch({ type: GET_CART, cartData: cartData })
}

const getCartHelper = (dispatch) => {
   window.testCart = () => {
      getApiManager().getCartItems().promise
         .then(res => {
            setCart(res.data, dispatch);
         });
   }

   getApiManager().getCartItems().promise
      .then(res => {
         setCart(res.data, dispatch);
      });

}

export const setCouponData = (coupon) => {
   return dispatch => {
      return getApiManager().preCheckoutCouponApply({ coupon_code: coupon }).promise
         .then(res => {
            if (res.data.status === 1) {
               const discount = parseFloat(res?.data?.discount.toFixed(2));
               dispatch({ type: SET_COUPON, discount, code: coupon });
            } else {
               dispatch({type: SET_COUPON_ERROR, error: res.data.message})
            }
            return res.data.status;
         })
         .catch(console.error);
   }
}

export const removeCoupon = () => {
   return dispatch => {
      dispatch({ type: REMOVE_COUPON });
   }
}

export const getCart = () => {
   return dispatch => {
      getCartHelper(dispatch);
   }
}

export const clearCartUtil = (dispatch) => {
   getApiManager().clearCart().promise
      .then(() => {
         dispatch({ type: CLEAR_CART })
      })
}

export const clearCart = () => {
   return dispatch => {
      clearCartUtil(dispatch);
   }
}

const getTransformedItemData = (id, amount, customizablesObj) => {

   const itemObj = {
      Item: {
         id: id,
         amount: amount,
      }
   }

   if (customizablesObj.hasOwnProperty('children')) {

      let childrenCustomizablesArr = [];
      customizablesObj.children.forEach((child) => {
         const curCustomizables = [];
         child.customizables.forEach((curVal) => {
            const itemIndex = curCustomizables.findIndex(el => el.id === curVal.optId);
            if (itemIndex !== -1) {
               curCustomizables[itemIndex].packs.push(curVal.packId);
            } else {
               curCustomizables.push({
                  id: curVal.optId,
                  packs: [curVal.packId]
               })
            }
         })

         childrenCustomizablesArr.push({
            id: child.id,
            amount: 1,
            customizables: curCustomizables
         });

      });



      itemObj.Item.children = childrenCustomizablesArr;

   } else {
      // transforms customizables
      let customizablesArr = [];
      customizablesObj.options.forEach((curVal, i) => {
         const itemIndex = customizablesArr.findIndex(el => el.id === curVal.optId);
         if (itemIndex !== -1) {
            customizablesArr[itemIndex].packs.push(curVal.packId);
         } else {
            customizablesArr.push({
               id: curVal.optId,
               packs: [curVal.packId]
            })
         }
      });

      itemObj.Item.customizables = customizablesArr;
   }

   // return itemObj object
   return itemObj;

}

export const addCartItem = (id, amount, customizablesObj) => {

   const itemData = getTransformedItemData(id, amount, customizablesObj);


   return dispatch => {
      getApiManager().addCartItem(itemData).promise
         .then(res => {
            setCart(res.data, dispatch);
         })
   }
}

export const removeCartItem = (index) => {
   return dispatch => {
      return getApiManager().removeCartItem({ Itemid: index }).promise
         .then(res => {
            // 
            setCart(res.data, dispatch);
         })
   }
}

export const sendVerifCode = (phone) => {
   return dispatch => {
      return getApiManager().sendVerificationCode({ phone_number: phone }).promise
   }
}

export const checkoutFormSubmit = (dataObj, verifCode, isScheduled = false, marker) => {
   return dispatch => {
      
      const newObj = {
         verification_code: verifCode,
         firstname: dataObj.firstName,
         lastname: dataObj.lastName,
         address: dataObj.street,
         tip: dataObj?.tip ?? '',
         phone_number: dataObj.phone,
         email: dataObj.email,
         comment: dataObj.comment,
         latitude: marker.x,
         longitude: marker.y,
         payment_type: dataObj.paymentMethod,
         delivery_type: parseInt(dataObj.deliveryMethod),
         coupon_code: dataObj?.coupon_code,
      }

      // deliveron keys
      if(dataObj?.id){
         newObj.id = dataObj.id;
      }
      if(dataObj?.companyId){
         newObj.companyId = dataObj.companyId;
      }
      if(dataObj?.deliveryPrice){
         newObj.deliveryPrice = dataObj.deliveryPrice;
      }
      if(dataObj?.type){
         newObj.type = dataObj.type;
      }
      if(dataObj?.companyName){
         newObj.companyName = dataObj.companyName;
      }
      if(dataObj?.deliveryToken){
         newObj.deliveryToken = dataObj.deliveryToken;
      }
      // /.

      if (isScheduled) {
         newObj.delivery_scheduled = dataObj.deliveryScheduled.toISOString().slice(0, 10) + ` ${dataObj.deliveryTime}:00`;
      }

      return getApiManager().checkout(newObj).promise;
   }
}