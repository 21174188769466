import React, {Component, createRef} from "react";
import CategorySlider from "./CategorySlider/CategorySlider";
import {connect} from "react-redux";
import Loader from "../common/Loader/Loader";
import WithStr from "../../hoc/withStr";
import s from "./Home.module.css";
import gsap from "gsap/gsap-core";
import {clearCart} from "../../store/reducers/cartReducer/cartActionCreators";
import {compose} from "redux";
import {showLocationModal, toggleWarningLocationPopup} from "../../store/reducers/appReducer";
import {initHomepage} from '../../store/reducers/homePageReducer';
import { withPopup } from "@kovzydev/module_productpopup";

import { GetModule, registerCallback, unregisterCallback } from '@kovzydev/module_manager'; 

class Home extends Component {
    state = {
        inputVal: '',
        pageLoaded: false,
        paymentModalText: '',
    }

    checkoutSuccessRef = createRef();
    failedPayment = ['FAIL', 'FAILED', 'REVERSED', 'AUTOREVERSED', 'TIMEOUT'];
    pendingPayment = ['CREATED', 'PENDING', 'PROCESSING'];
    decilnedPayment = ['DECLINED'];
    successfulPayment = ['OK', 'SUCCESS'];

    changeRedirectModalText = (paymentStatusArr, responseMessageArr, paymentStatus) => {
        for (let i = 0; i < paymentStatusArr.length; i++) {
            const index = paymentStatusArr[i].findIndex(el => {
                return el === paymentStatus.toUpperCase();
            })
            if (index !== -1) {
                this.setState({
                    paymentModalText: responseMessageArr[i]
                })
                return;
            }
        }

        // default error handler
        this.setState({
            paymentModalText: responseMessageArr[0]
        })
    }

    onLoadeCallback = () => {
        this.setState({
            pageLoaded: true
        });
    }

    componentDidMount() {
        Promise.all([
            registerCallback(() => {}),
            this.props.initHomepage()
        ])
        .then((res) => {
            this.onLoadeCallback();
        });

        gsap.set(this.checkoutSuccessRef.current, {display: 'none'});

        // set title for the homepage
        if(this.props.general?.website_title){
            document.querySelector('title').innerHTML = this.props.general?.website_title;
        }
        // 
    }

    componentWillUnmount() {
        unregisterCallback(() => {})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 
        const searchParams = new URLSearchParams(window.location.search);

        if (this.state.pageLoaded && this.state.pageLoaded !== prevState.pageLoaded && searchParams.get('payment')) {

            this.changeRedirectModalText(
                [this.failedPayment, this.pendingPayment, this.decilnedPayment, this.successfulPayment],
                [this.props.getStr('checkout_failed'), this.props.getStr('payment_status_processing') ?? this.props.getStr('checkout_pending'), this.props.getStr('checkout_declined'), this.props.getStr('checkout_success')],
                searchParams.get('payment')
            )


            this.props.clearCart();
            gsap.to(this.checkoutSuccessRef.current, {display: 'flex', opacity: 1});
            setTimeout(() => {
                gsap.to(this.checkoutSuccessRef.current, {
                    display: 'none', opacity: 0,
                });
            }, 3000);
        }

    }

    render() {

        return (
            <div>
                {!this.state.pageLoaded ? <Loader /> : null}

                <div ref={this.checkoutSuccessRef} className={s.checkoutSuccessWrp}>
                    <div className={s.bgDarkTransparent}></div>
                    <div className={s.checkoutSuccessText}>{this.state.paymentModalText}<i
                        className="fas fa-check-circle ml-2"></i></div>
                </div>

                <GetModule 
                    name="slider"
                    callback={this.props.handleAddToCartClick}
                    fallback={ <></> }
                    btnClassNames='px-4 py-2point5'
                />

                <CategorySlider categories={this.props.categories}/>

                <GetModule 
                    name="featured_products"
                    handleAddToCartClick={this.props.handleAddToCartClick}
                    fallback={ <></> }
                />

                <GetModule 
                    name="gallery"
                    fallback={ <></> }
                />

                <GetModule 
                    name="branches"
                    fallback={ <></> }
                />

            </div>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.homePage.categories,
    init: state.homePage.init,
    branchData: state.app.branchData,
    general: state.app.generalData,
})

const mapDispatchToProps = dispatch => ({
    toggleWarningLocationPopup: (isVisible) => dispatch(toggleWarningLocationPopup(isVisible)),
    clearCart: () => dispatch(clearCart()),
    showLocationModal: () => dispatch(showLocationModal()),
    initHomepage: () => dispatch(initHomepage())
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    WithStr,
    withPopup
)(Home);