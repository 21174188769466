import apiHandler from "../../api/api";

//action constants
const SET_JOBS = 'SET_JOBS';
const SET_JOB = 'SET_JOB';

//initial state
const initState = {
   jobs: [],
   singleJobData: {},
}

//reducer
const careerReducer = (state = initState, action) => {
   switch (action.type) {
      case SET_JOBS:
         return {
            ...state,
            jobs: action.jobs,
         }
      case SET_JOB:
         return {
            ...state,
            singleJobData: action.jobData,
         }
      default:
         return state;
   }
}

export default careerReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});


//thunk creators
export const getJobs = () => {
   return dispatch => {
      return apiHandler('getCareerPosts')
          .then(res => {
             dispatch({
                type: SET_JOBS,
                jobs: res
             })
          })
   }
}

export const getSingleJob = (id) => {
   return dispatch => {
      if(typeof id === 'string')
         id = parseInt(id);




      apiHandler('getCareerPost', {CareerPostid: id})
         .then(res => {
               dispatch({type: SET_JOB, jobData: res});
         })
   }
}

