import React, { Component } from "react";
import MobileNavbarSidebar from "./MobileNavbarSidebar";
import { Link } from "react-router-dom";
import { getLogoAddress, isMexicanaSite, linkWithLang } from "@kovzydev/module_helpers";
import styled from 'styled-components';

class DesktopNavbar extends Component {
    render() {

        const renderMessage = () => {
            if (!this.props.branchData) {
                return (
                    <div className={'position-relative w-100'}>
                        <LocationMessageArrow className='fas fa-sort-up' />
                        <LocationMessageWrp>
                            <div className='locationMessage'>
                                {this.props.getStr('branch_modal_title')}
                            </div>
                        </LocationMessageWrp>
                    </div>
                )
            }
            return null;
        }

        return (
            <MobileNavbarWrp>

                <MobileNavbarSidebar
                    pageNames={this.props.pageNames}
                    onSidebarCloseBtnClicked={this.props.onSidebarCloseBtnClicked}
                    showSidebar={this.props.showSidebar}
                    getStr={this.props.getStr}
                    languages={this.props.languages}
                    langId={this.props.langId}
                    chooseLangHandler={this.props.chooseLangHandler}
                    generalData={this.props.generalData}
                />

                <div className={'position-relative w-100'}>

                    <LogoWrp>
                        <Link to={linkWithLang('/')} className={'d-block'}>
                            <img src={getLogoAddress(this.props.generalData)} className='logo' alt="navbar-logo" />
                        </Link>
                    </LogoWrp>

                    {/*burger icon*/}
                    <BurgerWrp onClick={this.props.onSidebarBtnClicked} className='d-flex justify-content-start h-100'>
                        <div className={'d-flex align-items-center'}>
                            <i className='fas fa-bars burgerIcon'></i>
                        </div>
                    </BurgerWrp>
                    {/*/.*/}

                    {/*cart*/}
                    <CartWrp className='d-flex justify-content-end h-100'>
                        <div className='d-flex align-items-center'>

                            {!isMexicanaSite()
                                ? (
                                <div
                                    className='cartIconWrp'
                                    onClick={this.props.branchData ? this.props.onCartBtnClicked : this.props.showLocationModal}
                                >
                                    <CartAmount className='d-flex align-items-center justify-content-center'>
                                        {this.props.cartItemsAmount}
                                    </CartAmount>
                                    <i className='fas fa-shopping-cart cartIcon'></i>
                                </div>)
                                : null
                            }


                            <div className={`ml-2 locationIcon`} onClick={this.props.showLocationModal}>
                                <i className="fas fa-map-marker-alt"></i>
                                {renderMessage()}
                            </div>

                        </div>
                    </CartWrp>
                    {/*/.*/}

                </div>
            </MobileNavbarWrp>
        )
    }
}

export default DesktopNavbar;

// logo
const LogoWrp = styled.div`
    text-align: center;
    .logo{
        height: 55px;
    }
`;
// /.

const MobileNavbarWrp = styled.div`
    @media (min-width: 1100px) {
        display: none;
    }

    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    background: ${props => props.theme.background.colors.primary};
    z-index: 9;
    -webkit-box-shadow: 0px 9px 14px -4px rgba(0,0,0,0.18);

    .cartIcon, .burgerIcon, .locationIcon{
        margin-right: 10px;
        color: ${props => props.theme.text.colors.primary};
        font-size: 25px;
        cursor: pointer;
    }

    .cartIconWrp{
        position: relative;
    }
`;

const CartAmount = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -10px;
    right: 2px;
    background: #777;
    color: white;
`;

const BurgerWrp = styled.div`
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    transition: opacity .3s ease;
`

const CartWrp = styled.div`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    transition: opacity .3s ease;
`;

const LocationMessageWrp = styled.div`
    position: absolute;
    top: 9px;
    right: -9px;
    width: 200px;
    border-radius: 10px;
    
    .locationMessage{
        padding: 10px;
        border-radius: 10px;
        background: #707070;
        color: white;
        font-size: 1rem;
    }
`;

const LocationMessageArrow = styled.i`
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 27px;
    color: #707070;
    z-index: 2;
`;