import React from 'react';
import styled from 'styled-components';

const LocationSearchForm = (props) => {
    return (
        <Wrp className={'mb-3'}>

            <label
                className='mb-2 font-weight-bold'
                htmlFor="modal-address">
                {props.getStr('address_field')}
            </label>

            <div className={'d-flex align-items-stretch'}>

                <input
                    type="text"
                    id={'modal-address'}
                    className={'form-control h-auto w-auto flex-grow-1'}
                    value={props.inputVal}
                    onChange={(e) => props.addressInputChangeHandler(e.target.value)}
                    onFocus={props.onAddressFieldFocus}
                    onBlur={props.onAddressFieldBlur}
                />


                <div className={'h-auto mx-1'}>
                    <button
                        ref={props.addressSearchBtnRef}
                        className={'btn btn-secondary h-100'}
                        onClick={props.submitInputValueToMaps}
                    >
                        <i className="fas fa-search"></i>
                    </button>
                </div>

                <div className={'h-auto'}>
                    <button
                        className={'btn btn-danger h-100'}
                        onClick={props.setCurrentLocation}
                    >
                        <i className="fas fa-map-marker-alt"></i>
                    </button>
                </div>
            </div>

        </Wrp>
    );
};

export default LocationSearchForm;

const Wrp = styled.div`
    label{
        color: ${props => props.theme.text.colors.primary}
    }
`;