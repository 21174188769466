//css
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
///.

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import store from './store/redux-store';
import {BrowserRouter as Router} from "react-router-dom";
import Theme from './theme.config';

window.THEME_CONFIG = Theme;

ReactDOM.render(
   <Provider store={store}>
      <Router basename={'/'}>
         <App/>
      </Router>
   </Provider>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
