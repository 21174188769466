import React from 'react';

import 'swiper/swiper-bundle.css';
import 'nouislider/distribute/nouislider.css';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';

import { withRouter } from 'react-router-dom';

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/common/Loader/Loader";
import BusyModal from "./components/common/BusyModal/BusyModal";
import LocationModal from "./components/LocationModal/LocationModal";

import {connect} from "react-redux";
import {chooseUserBranch, getGeneralData, getUserBranch, showLocationModal, toggleWarningLocationPopup} from "./store/reducers/appReducer";
import {setProductPopupId} from './store/reducers/popupReducer/popupReducerActionCreators';
import withLang from "./hoc/withLang/withLang";
import {compose} from "redux";
import {Style} from "react-style-tag";
import Geocode from "react-geocode";
import TagManager from 'react-gtm-module'

// social media and tracking
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import Messenger from 'components/common/Messenger/Messenger';
// /.

// routes
import Routes from './components/Router/routes';
// .

// kovzy
import {getIconAddress, getShowProductPopupQueryParam, toggleShopProductPopupQueryParam} from "@kovzydev/module_helpers";
import { registerCallback } from '@kovzydev/module_manager';
import { withPopup } from '@kovzydev/module_productpopup';
// /.


export const matchDomain = (domains = []) => {
    if(!domains || domains?.length === 0){
        return false;
    }

    if(domains?.length > 0){
        for(let x = 0; x < domains.length; x++){
            const domain = domains[x];
            const siteUrlDomainMatchesTheCurrentDomain = window.location.hostname.includes(domain);
            if(siteUrlDomainMatchesTheCurrentDomain){
                return { domain };
            }
        }
    }

    return false;
}

export const initializeFBDomainVerificationMetaForSpecificDomains = () => {
    const domainMappedToCode = {
        'fizculture.ge': '2b7ezfmjf3ompibl8t415a9u4uy8bv',
    }

    const { domain } = matchDomain(Object.keys(domainMappedToCode));
    if(domain){
        const meta = document.createElement('meta')
        meta.name = 'facebook-domain-verification'
        meta.content = domainMappedToCode[domain]
        document.querySelector('head').append(meta)    
    }
}

class App extends React.Component {

    constructor() {
        super();
        this.state = {
            modulesInitialized: false
        };
    }

    modulesInit = () => {
        this.setState({
            modulesInitialized: true
        });
    };

    getShowProductPopupQueryParam = () => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('showproduct');
        if(id && !isNaN(parseInt(id))){
            return id;
        }
        return undefined;
    }

    removeShowProductPopupQueryParam = () => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('showproduct');
        if(id){
            params.delete('showproduct');
            const newLink = window.location.pathname + '?' + params.toString();
            this.props.history.push(newLink)
        }
    }

    // TODO: this is temporary code for fizculture only, Google Tag Manager will be implemented as feature later
    initializeGoogleTagManagerForSpecificDomains = () => {
        const domainMappedToCode = {
            'dev.kovzy.com': 'GTM-TNKPC2G',
            'fizculture.ge': 'GTM-WFDXM3X',
            'quechido.ge': 'GTM-NKWTHXP'
        }
        const { domain } = matchDomain(Object.keys(domainMappedToCode));
        if(domain){
            const googleTagManagerCode = domainMappedToCode[domain];
            const googleTagManagerInitParam = { gtmId: googleTagManagerCode }
            TagManager.initialize(googleTagManagerInitParam)
        }
    }

    componentDidMount() {
        
        
        registerCallback(this.modulesInit);
        
        this.props.getGeneralData();
        
        this.props.getUserBranch()
            .then((res) => {
                if(getShowProductPopupQueryParam()){
                    this.handleAddToCartClick(getShowProductPopupQueryParam());
                }
            })

        this.initializeGoogleTagManagerForSpecificDomains()
        initializeFBDomainVerificationMetaForSpecificDomains()
    }

    componentDidUpdate(prevProps) {
        if (this.props.langId !== prevProps.langId) {
            this.props.getGeneralData();
        }

        if (prevProps.general !== this.props.general) {
            // set site title
            document.title = this.props.general.website_title;

            // dynamically change favicon
            const link = document.querySelector('#favicon');
            link.href = getIconAddress(this.props.general);

            /*
             set-up google analytics
             and make it track pageviews
            */
            if(this.props.general.integrations.integration_google_analytics_tracking_id){
                ReactGA.initialize(this.props.general.integrations.integration_google_analytics_tracking_id);
                ReactGA.send('pageview');
            }
            // /.

            // facebook pixel setup
            let pixelId;
            let integrationsData = this.props?.general?.integrations;
            if(integrationsData?.data){
                pixelId = integrationsData?.data?.integration_facebook_pixel_id;
            } else {
                pixelId = integrationsData?.integration_facebook_pixel_id;
            }

            if(pixelId){
                ReactPixel.init(pixelId, undefined, {
                    autoConfig: true,
                    debug: false,
                });
                ReactPixel.pageView(); // For tracking page view

            }


            this.getShowProductPopupQueryParam();
        }

        if(this.props.popupProductId !== prevProps.popupProductId){
            const prodId = this.props.popupProductId;
            if(prodId){
                if(this.props.branchData){
                    this.props.onRenderPopup(prodId);
                } else {
                    this.props.showLocationModal();
                }
            }
        }

        if(!this.props.showPopup
            && !this.props.branchIsLoading
            && this.props.branchIsLoading !== prevProps.branchIsLoading
            && this.getShowProductPopupQueryParam()
        ){
            this.handleAddToCartClick(this.getShowProductPopupQueryParam());
        }
    }

    handleAddToCartClick = (productId) => {
        
        if(this.props.branchData){
            // remove showproduct=[id] from url
            this.removeShowProductPopupQueryParam();
            // show popup that asks to give us access to location
            this.props.toggleWarningLocationPopup(false);
            // show product popup
            this.props.onRenderPopupHandler(productId);
        } else {

            if(!navigator?.permissions){
                if(navigator?.geolocation?.getCurrentPosition){
                    navigator.geolocation.getCurrentPosition((pos) => {
                        Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
                            response => {
                                const address = response.results[0].formatted_address;
                                this.props.chooseUserBranch(
                                    address,
                                    pos.coords.latitude,
                                    pos.coords.longitude,
                                    null,
                                )
                            },
                            error => {
                                this.props.showLocationModal();
                                console.error(error);
                            }
                        )
                    }, () => {
                        this.props.showLocationModal();
                    })
                }
                return;
            }

            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if(result.state === 'prompt'){
                    // show popup that asks to give us access to location
                    this.props.toggleWarningLocationPopup(true);
                    // update url and set new showproduct=[id]
                    toggleShopProductPopupQueryParam({
                        history: this.props.history,
                        argId: productId
                    });
                } else if (result.state === 'denied') {
                    // if this user denies to give his location show location selection popup, so he can select manually
                    this.props.showLocationModal()
                    // update url and set new showproduct=[id]
                    toggleShopProductPopupQueryParam({
                        history: this.props.history,
                        argId: productId
                    });
                } else {
                    if(navigator?.geolocation?.getCurrentPosition){
                        navigator.geolocation.getCurrentPosition((pos) => {
                            Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
                                response => {
                                    const address = response.results[0].formatted_address;
                                    this.props.chooseUserBranch(
                                        address,
                                        pos.coords.latitude,
                                        pos.coords.longitude,
                                        null,
                                    )
                                },
                                error => {
                                    this.props.showLocationModal();
                                    console.error(error);
                                }
                            )
                        }, () => {
                            this.props.showLocationModal();
                        })
                    }
                }
            });
        }
    }

    render() {

        if (!this.props.general || !this.props.languages || this.props.languages.length === 0 || this.props.branchIsLoading || !this.state.modulesInitialized) {
            return <Loader/>;
        }


        return (
            <>
                <Style>
                {`
                    body{
                        background-color: ${window.THEME_CONFIG.background.colors.primary};
                    }
                `}
                </Style>
                <Navbar
                    languages={this.props.languages}
                    langId={this.props.langId}
                    chooseLangHandler={this.props.chooseLangHandler}
                />

                
                <div className='d-flex flex-column main-layout-wrp'>

                    <div style={{flex: '1 0 auto'}}>
                        
                        <BusyModal
                            generalData={this.props.general}
                        />

                        <LocationModal
                            generalData={this.props.general}
                        />

                        <Routes
                            general={this.props.general}
                            handleAddToCartClick={(prod_id) => {
                                this.handleAddToCartClick(prod_id);
                            }}
                        />
                    </div>


                    <div style={{flexShrink: '0'}}>
                        <Footer
                            general={this.props.general}
                        />
                    </div>

                    <Messenger page_id={ this.props.general?.integrations?.integration_facebook_messenger_page_id } />

                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    popupProductId: state.popup.id, 
    showPopup: state.popup.showPopup,
    lang: state.app.lang,
    general: state.app.generalData,
    languages: state.app.languages,
    langId: state.app.langId,
    showMainAlert: state.app.showMainAlert,
    branchData: state.app.branchData,
    branchIsLoading: state.app.branchIsLoading,
});

const mapDispatchToProps = dispatch => ({
    toggleWarningLocationPopup: (isVisible) => dispatch(toggleWarningLocationPopup(isVisible)),
    showLocationModal: () => dispatch(showLocationModal()),
    setProductPopupId: (id) => dispatch(setProductPopupId(id)),
    getGeneralData: () => dispatch(getGeneralData()),
    getUserBranch: () => dispatch(getUserBranch()),
    chooseUserBranch: (address, latitude, longitude) => dispatch(chooseUserBranch(address, latitude, longitude)),
});


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    withPopup
)(App);
