import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';


import {Col, Container, Dropdown, Row} from "react-bootstrap";
import {Products} from "@kovzydev/module_commoncomponents";
import {getCategories, getProducts, getProductsByCategory, PRODUCTS_LIMIT} from "../../store/reducers/shopPageReducer";
import { withPopup } from "@kovzydev/module_productpopup";
// @ts-ignore
import Loader from "../common/Loader/Loader";
import CategorySlider from "../Home/CategorySlider/CategorySlider";
import {showLocationModal} from "../../store/reducers/appReducer";
// @ts-ignore
import styled, { ThemeProvider } from 'styled-components'
import { getStrHelper } from '@kovzydev/module_helpers';
import Sort from './Filters/Sort/Sort';
// @ts-ignore-error
import { Link } from "react-router-dom";

type filtersBaseType = {
    search: string,
    sort: 'desc'|'asc',
}
 

type filtersType = {
    catId: number|null,
} & filtersBaseType

type filtersQueryType = {
    category: string|null,
} & filtersBaseType

const Shop = (props : any) => {
    
    // state
    const [filters, setFilters]                 = useState<filtersType | null>(null);
    const [searchField, setSearchField]         = useState<string>('');
    const [page, setPage]                       = useState<number>(0);
    const [productsLoaded, setProductsLoaded]   = useState<boolean>(false);

    // refs
    const productsWrpRef = useRef(null);

    // helper functions
    const getFiltersObjFromQuery = () => {
        const searchParams = new URLSearchParams(props.location.search);
        const newObj : filtersQueryType = {
            category:   searchParams.has('category')  ? searchParams.get('category') as string                  : null,
            search:     searchParams.has('search')    ? searchParams.get('search') as string                    : '',
            sort:       searchParams.has('sort')      ? String(searchParams.get('sort')) as 'asc' : 'desc',
        }
        
        return newObj;
    }

    const initFilters = async () => {
        const { category, search, sort } = getFiltersObjFromQuery();
        // load categories
        const mappedCategories = await props.getCategories();

        // find category using our category name taken from query param
        const currentCatId = mappedCategories.find((cat : any) => cat.name === category)?.id;

        const generatedFiltersObject = {
            catId: currentCatId,
            search,
            sort
        };

        if(currentCatId){
            generatedFiltersObject.catId = currentCatId;
        }

        setFilters(generatedFiltersObject);
    }

    const getPage = () => {
        const searchParams = new URLSearchParams(props.location.search);
        const page = searchParams.has('page') ? parseInt(searchParams.get('page') as string) : 0;
        return page;
    }

    const onCategoryClicked = (categoryId : number) => {
        setFilters({
            ...filters,
            catId: categoryId
        } as filtersType);

        setPage(0);
    }

    const onSearchSubmitHandler = (e : FormEvent) => {
        e.preventDefault();
        // set search query string
        // const searchParams = new URLSearchParams(props.location.search);
        props.history.push(`/shop?search=${searchField}`);
        // set search in filters state
        setFilters({
            ...filters,
            catId: null,
            sort: 'desc',
            search: searchField
        } as filtersType);
    }

    const onSortClicked = (method : 'asc' | 'desc') => {
        setFilters({
            ...filters,
            sort: method
        } as filtersType);
    }

    const onPaginationClicked = (pageNum : number) => {
        setPage(pageNum);
    }
    
    // lifecycle methods
    useEffect(() => {
        // init filters
        initFilters();
        // init pagination
        setPage(getPage());
    }, [])

    useEffect(() => {
        if(filters){
            setProductsLoaded(false);
            if(filters?.catId){
                props.getProductsByCategory(filters, page).then(() => {
                    setProductsLoaded(true);
                    window.scroll(0, 0);
                })
            } else {
                props.getProducts(filters, page).then(() => {
                    setProductsLoaded(true);
                    window.scroll(0, 0);
                })
            }
        }
    }, [filters, page]);


    // JSX functions
    // TODO: move these into their own components
    const searchJSX = () => (
        <Col xs={7} lg={5}>
            <form onSubmit={onSearchSubmitHandler}>
                <div className={'d-flex'}>
                    <input
                        type="text"
                        placeholder={getStrHelper('search_placeholder')}
                        value={searchField}
                        onChange={(e) => {
                            setSearchField(e.target.value);
                        }}
                        className='searchForm form-control py-4'
                    />
                    <button className='searchBtn'>
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </form>
        </Col>
    );

    const countJSX = () => {
        const countJSXArr = [];
        for (let x = 0; x < props.count / PRODUCTS_LIMIT; x++) {
            const queryParams = new URLSearchParams(props.location.search);
            queryParams.set('page', String(x));
            countJSXArr.push(
                <Link
                    key={x}
                    to={'?' + queryParams.toString()}
                    onClick={ () => onPaginationClicked(x) }
                    className={`pag ${page === x ? 'active' : ''}`}
                >
                    {x + 1}
                </Link>
            )
        }

        return (
            <div className={'d-flex mt-4'}>
                {countJSXArr}
            </div>
        );
    }

    const categorySliderJSX = () => {
        // @ts-ignore
        return <CategorySlider categories={props.categories} chosenCatId={ filters?.catId ?? null} onCategoryClicked={onCategoryClicked} />
    }
    // /.

    return (
        <ThemeProvider theme={
            // @ts-expect-error 
            window.THEME_CONFIG
        }>
            <div className={'h-100 pb-2'}>

            {!productsLoaded ? <Loader /> : null}

            <Wrp>

                {categorySliderJSX()}
                
                <Container>
                    <Row>

                        <Col xs={12} className={'mb-4'}>
                            <Row className='justify-content-between'>

                                {searchJSX()}
                                <Sort
                                    filters={filters}
                                    location={props.location}
                                    onSortClicked={onSortClicked}
                                />

                                <Col ref={productsWrpRef} xs={12} className='mt-4'>
                                    {
                                        productsLoaded && (!props.products || props.products.length === 0)
                                            ? <PrimaryText>No products found for this category</PrimaryText>
                                            : <Products
                                                handleClick={props.handleAddToCartClick}
                                                productsArr={props.products}
                                                sizeMixins={{ sm: 4 }}
                                                className={'mb-4'}
                                            />
                                    }

                                    {countJSX()}
                                </Col>


                            </Row>

                        </Col>


                    </Row>

                </Container>
            </Wrp>
        </div>
        </ThemeProvider>
    )
}

const mapStateToProps = (state : any) => ({
    categories: state.shop.categories,
    products: state.shop.products,
    count: state.shop.count,
    page: state.shop.page,
    branchData: state.app.branchData,
    general: state.app.generalData,
});

const mapDispatchToProps = (dispatch : any) => ({
    getProducts: (data : any, page : any) => dispatch(getProducts(data, page)),
    getProductsByCategory: (data : any, page : any) => dispatch(getProductsByCategory(data, page)),
    getCategories: () => dispatch(getCategories()),
    showLocationModal: () => dispatch(showLocationModal())
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPopup
)(Shop);










const PrimaryText = styled.p`
    color: ${(props : any) => props.theme.text.colors.primary}
`;
const Wrp = styled.div`
    background: ${(props : any) => props.theme.background.colors.secondary};
    margin: 0px 0px 50px;
    padding: 10px 0 50px;
    height: 100%;
    
    .searchBtn{
        padding: 5px 20px;
        background: ${(props : any) => props.theme.button.primary.colors.normalBg};
        color: ${(props : any) => props.theme.button.primary.colors.normalText};
        box-shadow: none;
        border: none;
        font-size: 16px;
        &:hover{
            transition: all .3s ease;
            background: ${(props : any) => props.theme.button.primary.colors.hoverBg};
            color: ${(props : any) => props.theme.button.primary.colors.hoverText};
        }
    }
    
    .searchForm:focus{
        outline: none;
        box-shadow: none;
    }
    
    .sortByMobileWrp{
        display: block;
        margin: 30px 0;
    }

    .dropdown-toggle{
        color: ${(props : any) => props.theme.button.categorySliderButton.colors.normalText} !important;
        background: ${(props : any) => props.theme.button.categorySliderButton.colors.normalBg} !important;
        &:focus, &:active, &:hover{
            color: ${(props : any) => props.theme.button.categorySliderButton.colors.hoverText} !important;
            background: ${(props : any) => props.theme.button.categorySliderButton.colors.hoverBg} !important;
        }
    }

    .sortByDesktop, .sortByMobile{
        font-size: 16px;
        padding: 10px;
        border-radius: 0;
    }
    
    
    .dropdownItem{
        padding: 10px;
        border-top: 1px solid #F3F4F4;
        display: block;
        color: ${(props : any) => props.theme.button.categorySliderButton.colors.normalText} !important;
        background: ${(props : any) => props.theme.button.categorySliderButton.colors.normalBg} !important;

        &.active{
            color: ${(props : any) => props.theme.button.categorySliderButton.colors.hoverText} !important;
            background: ${(props : any) => props.theme.button.categorySliderButton.colors.hoverBg} !important;
        }

        &:hover{
            opacity: 0.8; 
        }
    }
    
    .dropdownItem:first-child{
        border-top: none;
    }
    
    .pag{
        padding: 5px 9px;
        color: ${(props : any) => props.theme.button.categorySliderButton.colors.normalText} !important;
        background: ${(props : any) => props.theme.button.categorySliderButton.colors.normalBg} !important;
        &.active, &:hover{
            color: ${(props : any) => props.theme.button.categorySliderButton.colors.hoverText} !important;
            background: ${(props : any) => props.theme.button.categorySliderButton.colors.hoverBg} !important;
        }
    }
    
    @media (min-width: 992px) {
        .sortByDesktopWrp{
            display: block;
        }
    
        .sortByMobileWrp{
            display: none;
        }
    }
`;