import React from 'react';
import {Col, Row} from "react-bootstrap";
import ContactMap from "./ContactMap";
import { getStrHelper } from '@kovzydev/module_helpers';
import styled from 'styled-components';

const Branch = ({
    latitude,
    longitude,
    address,
    phone,
    general
}) => {
    
    const renderMail = () => {
        if(general.email){
            return(
                <li className='contactItem'>{getStrHelper('email')}: <a
                href={'mailto:' + general.email}>{general.email}</a></li>
            )
        }
    }


    return (
        <Wrp>

            <Col md='6' className='pr-md-0 px-0 pl-auto order-md-2'>
                <div className='mapWrp'>
                    <ContactMap
                        mapMarker={{
                            centerX: latitude,
                            centerY: longitude,
                        }}
                        zoom={15}
                    />
                </div>
            </Col>

            <Col md='6' >
                <div className={'py-5 p-4'}>
                    <h3 className='title'>{address}</h3>
                    <ul className='contactData'>
                        <li className='contactItem'>{getStrHelper('phone')}: <a
                            href={'tel:' + phone}>{phone}</a></li>
                        {renderMail()}
                    </ul>
                    <div className={'d-flex mt-3'}>
                        <a href={general.facebook_link} target='_blank' rel="noopener noreferrer"
                           className='iconWrp'>
                            <i className="fab fa-facebook-f"></i>
                        </a>

                        <a href={general.instagram_link} target='_blank' rel="noopener noreferrer"
                           className='iconWrp'>
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </Col>

        </Wrp>
    );
};

export default Branch;

const Wrp = styled(Row)`
box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
margin-bottom: 30px;
background: ${props => props.theme.background.colors.primary};

.title{
    margin-bottom: 30px;
}

.title, .par{
    color: ${props => props.theme.text.colors.primary};
}

.par{
    font-size: 16px;
}

.mapWrp{
    width: 100%;
    height: 300px;
}

.mapWrp > div{
    height: 100%;
}

.marker{
    width: 50px;
}

.contactData{
    padding: 25px 0;
    border-top: 1px solid ${props => props.theme.text.colors.primary};
    border-bottom: 1px solid ${props => props.theme.text.colors.primary};
    list-style-type: none;
    color: ${props => props.theme.text.colors.primary};
}

.contactItem:first-child{
    margin-top: 0;
}

.contactItem{
    margin-top: 10px;
    font-size: 16px;
}

.iconWrp{
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #000;
    color: white;
    margin-right: 10px;
}

.iconWrp i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
    .mapWrp{
        height: 100%;
    }
}
`;