import React, {Component} from 'react';
import s from './Categories.module.css';
import bannerPlaceholder from "../../assets/images/banner2.jpg";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";
import withStr from "../../hoc/withStr";
import {getCategories} from "../../store/reducers/shopPageReducer";
import Loader from "../common/Loader/Loader";
import {imagePrepath, pageImageNames} from "@kovzydev/module_helpers";
import CategoryItem from "./CategoryItem";
import styled, { ThemeProvider } from 'styled-components';

class Categories extends Component {

    componentDidMount() {
        this.props.getCategories();
    }

    render() {

        if (!this.props.categories) {
            return <Loader/>
        }

        const categoriesJSX = () => {
            if(Array.isArray(this.props.categories) && this.props.categories.length > 0){
                return this.props.categories.map(cat => {
                    return (
                        <CategoryItem
                            key={cat.id}
                            id={cat.id}
                            image_name={cat.image_name}
                            name={cat.name}
                        />
                    )
                });
            }

            return (
                <Col>
                    <h4 className={'text-center'}>{this.props.getStr('no_results')}</h4>
                </Col>
            )
        }


        const imageName = this.props.general[pageImageNames.categories];
        const bannerImagePath = imageName ? imagePrepath(imageName) : bannerPlaceholder;

        return (
            <ThemeProvider theme={window.THEME_CONFIG}>
                <div>
                    <TitleBanner imageURL={bannerImagePath} text={this.props.getStr('categories')}/>
                    <Wrp>
                        <Container>
                            <Row>
                                {categoriesJSX()}
                            </Row>
                        </Container>
                    </Wrp>
                </div>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    general: state.app.generalData,
    categories: state.shop.categories,
})

const mapDispatchToProps = dispatch => ({
    getCategories: () => dispatch(getCategories())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStr(Categories));

const Wrp = styled.div`
    background: ${props => props.theme.background.colors.secondary};
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 50px;
`;