import React from 'react';
import {Col, Container, Dropdown, Row} from "react-bootstrap";
import { getStrHelper } from '@kovzydev/module_helpers';
import cx from 'classnames';
// @ts-ignore
import { Link } from 'react-router-dom';

const Sort : React.FC<{
    filters: any,
    location: any,
    onSortClicked : any
}> =({
    filters,
    location,
    onSortClicked
}) => {
    const sortLinks = {
        asc: new URLSearchParams(location.search),
        desc: new URLSearchParams(location.search),
    }

    sortLinks.asc.set('sort', 'asc');
    sortLinks.desc.set('sort', 'desc');

    if(!filters){
        return null;
    }

    const { sort } = filters;
    
    return (
        <Col xs={5} lg={5} className='sortByDesktopWrp'>
            <div className='d-flex justify-content-end'>
                <Dropdown>
                    <Dropdown.Toggle
                        variant="light"
                        id="dropdown-basic"
                        className='sortByDesktop border-none outline-none shadow-none'
                    >
                        {getStrHelper('sort')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'py-0 bg-transparent'}>
                        
                        <Link
                            to={`?${sortLinks.asc.toString()}`}
                            className={cx([
                                'dropdownItem border-none',
                                {active: sort === 'asc'}
                            ])}
                            onClick={() => {
                                onSortClicked('asc');
                                document.body.click();
                            }}
                        >
                            {getStrHelper('asc')}
                        </Link>

                        <Link
                            to={`?${sortLinks.desc.toString()}`}
                            className={cx(['dropdownItem', {active: sort === 'desc'}])}
                            onClick={() => {
                                onSortClicked('desc');
                                document.body.click();
                            }}
                        >
                            {getStrHelper('desc')}
                        </Link>

                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Col>
    )
}

export default Sort;