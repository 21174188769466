import React from 'react';
import bannerImg from "../../../assets/images/5.jpg";
import TitleBanner from "../../common/TitleBanner/TitleBanner";

const CheckoutNotAvaliable = props => {
    return (
        <div>
            <TitleBanner imageURL={props.bannerImg} text={props.getStr('checkout')}/>
            <div className={'py-4 text-center text'}>
                <h3 className={'mt-5'}>{props.getStr('checkout_unavailable') || 'Checkout Unavailable'}</h3>
                <p className={'mt-3'}>{props.getStr('no_delivery_or_payment') || 'No Delivery or Payment Methods'}</p>
            </div>
        </div>
    )
}

export default CheckoutNotAvaliable;