import SliderImage1 from './assets/images/sliderDefault/food.jpg';
import SliderImage2 from './assets/images/sliderDefault/fruits.jpg';
// banner placeholder
import bannerPlaceholder1 from "./assets/images/banner1.jpg";
import bannerPlaceholder2 from "./assets/images/banner2.jpg";
import popupBannerPlaceholder from './assets/images/banner-placeholder.jpg';

const generic_light = {
    background: {
        colors: {
            primary: '#fff',
            secondary: '#f5f5f5',
            third: '#dbdbdb',
        }
    },
    text: {
        colors: {
            primary: '#212529',
            secondary: '#454545',
            special: '#e0c124',
            subtle: '#ddd',
        }
    },

    cartSidebar: {
        amount: {
            bgColor: '#e0c124',
            textColor: '#313131',
        },
        checkoutBtn: {
            colors: {
                normalBg: '#fff',
                normalText: '#000',
                hoverBg: '#313131',
                hoverText: '#fff',
            }
        }
    },

    checkoutPage: {
        headlinIcon: {
            bgColor: '#e0c124',
            textColor: '#313131',
        }
    },

    defaultImages: {
        slider: [SliderImage1, SliderImage2],
        blog: bannerPlaceholder2,
        career: bannerPlaceholder1,
        singleJob: bannerPlaceholder1,
        PopupBanner: popupBannerPlaceholder
    },

    button: {
        circularIconButton: {
            colors: {
                normalBg: '#000',
                hoverBg: '#383c40',
                normalText: '#fff',
                hoverText: '#fff',
            }
        },

        sectionDetailsButton: {
            colors: {
                normalBg: 'transparent',
                hoverBg: '#707070',
                normalText: '#707070',
                hoverText: '#fff',
            }
        },

        sliderButton: {
            colors: {
                normalBg: '#fff',
                hoverBg: '#eee',
                normalText: '#000',
                hoverText: '#000',
            }
        },

        categorySliderButton: {
            colors: {
                normalBg: '#fff',
                normalText: '#000',
                hoverBg: '#313131',
                hoverText: '#fff',
            }
        },

        primary: {
            colors: {
                normalBg: '#fff',
                hoverBg: '#313131',
                normalText: '#313131',
                hoverText: '#fff',
            }
        },

        secondary: {
            colors: {
                normalBg: 'transparent',
                hoverBg: '#707070',
                normalText: '#707070',
                hoverText: '#fff',
            }
        },

        danger: {
            colors: {
                normalBg: '#c74646',
                hoverBg: '#a63a3a',
                normalText: '#fff',
                hoverText: '#fff',
            }
        }
    },
};

const generic_dark = {
    background: {
        colors: {
            third: '#191919',
            primary: '#4d4d4d',
            secondary: '#313131',
        }
    },
    text: {
        colors: {
            primary: '#fff',
            secondary: '#b6b6b6',
            special: '#e0c124',
            subtle: '#555',
        }
    },

    cartSidebar: {
        amount: {
            bgColor: '#e0c124',
            textColor: '#313131',
        },
        checkoutBtn: {
            colors: {
                normalBg: '#717171',
                hoverBg: '#a8a8a8',
                normalText: '#fff',
                hoverText: '#fff',
            }
        }
    },

    checkoutPage: {
        headlinIcon: {
            bgColor: '#e0c124',
            textColor: '#313131',
        }
    },

    defaultImages: {
        slider: [SliderImage1, SliderImage2],
        blog: bannerPlaceholder2,
        career: bannerPlaceholder1,
        singleJob: bannerPlaceholder1,
        PopupBanner: popupBannerPlaceholder
    },

    button: {
        circularIconButton: {
            colors: {
                normalBg: '#818181',
                hoverBg: '#818181',
                normalText: '#fff',
                hoverText: '#fff',
            }
        },

        sectionDetailsButton: {
            colors: {
                normalBg: 'transparent',
                hoverBg: '#707070',
                normalText: '#707070',
                hoverText: '#fff',
            }
        },

        sliderButton: {
            colors: {
                normalBg: '#fff',
                hoverBg: '#eee',
                normalText: '#000',
                hoverText: '#000',
            }
        },

        categorySliderButton: {
            colors: {
                normalBg: '#717171',
                hoverBg: '#a8a8a8',
                normalText: '#fff',
                hoverText: '#fff',
            }
        },

        primary: {
            colors: {
                normalBg: '#818181',
                hoverBg: '#959595',
                normalText: '#fff',
                hoverText: '#fff',
            }
        },

        secondary: {
            colors: {
                normalBg: 'transparent',
                hoverBg: '#707070',
                normalText: '#707070',
                hoverText: '#fff',
            }
        },

        danger: {
            colors: {
                normalBg: '#c74646',
                hoverBg: '#a63a3a',
                normalText: '#fff',
                hoverText: '#fff',
            }
        }
    },
};

const themes = {
    generic_light,
    generic_dark
};

const exported = themes[process.env.REACT_APP_THEME ?? 'generic_light'];
console.log(exported, process.env.REACT_APP_THEME)

export default exported;