import React from 'react';

import {
    Switch,
    Route, Redirect,
} from 'react-router-dom';

import Home from "../Home/Home";
import Shop from "../Shop/Shop";
import Contact from "../Contact/Contact";
import Checkout from "../Checkout/Checkout";
import PageNotFound from "../PageNotFound/PageNotFound";
import Categories from "../Categories/Categories";


import { getRegistrationData } from '@kovzydev/module_manager';

const Routes = ({
    general,
    handleAddToCartClick
}) => {
    return (
        <Switch>
            <Route exact path={'/'}
                render={
                    (routeProps) => <Home {...routeProps} handleAddToCartClick={handleAddToCartClick} />
                }
            />
            
            <Route exact path={'/shop'}
                render={
                    (routeProps) => <Shop {...routeProps} handleAddToCartClick={handleAddToCartClick} />
                }
            />
            <Route exact path={'/categories'} component={Categories}/>
            
            <Route exact path={'/contact'}
                    render={(routeProps) => <Contact {...routeProps} general={general}/>}/>
            <Route exact path={'/checkout'} component={Checkout}/>

            {/* invoice */}
            <Route path={'/invoice/:id'} render={() => <Redirect to={'/'}/>}/>
            {/* /. */}

            {  getRegistrationData('getRoutes').map((routesState, i) => {
                const routes = routesState();
                
                return routes.map((props, j) => {
                    return <Route key={ `${i}_${j}` } {...props} />;
                });
            }) }

            <Route path={'*'} component={PageNotFound}/>

        </Switch>
    );
};

export default Routes;