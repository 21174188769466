import React, {Component} from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';

class LoaderComp extends Component {
   render() {
      return (
         <ThemeProvider theme={window.THEME_CONFIG}>
            <Wrp>
               <LoadingioSpinnerRolling>
                  <LoaderStyled>
                     <div></div>
                  </LoaderStyled>
               </LoadingioSpinnerRolling>
            </Wrp>
         </ThemeProvider>
      );
   }
}

export default LoaderComp;

const spinnerAnimation = keyframes`
   from {
      transform: translate(-50%,-50%) rotate(0deg);
   }
   to {
      transform: translate(-50%,-50%) rotate(360deg);
   }
`;

const Wrp = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: ${props => props.theme.background.colors.secondary};
   z-index: 9999;
`;

const LoadingioSpinnerRolling = styled.div`
   width: 200px;
   height: 200px;
   display: inline-block;
   overflow: hidden;

   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
`;

const LoaderStyled = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
   transform: translateZ(0) scale(1);
   backface-visibility: hidden;
   transform-origin: 0 0;

   div{
      box-sizing: content-box;
      position: absolute;
      top: 100px;
      left: 100px;

      width: 120px;
      height: 120px;
      
      border: 20px solid ${props => props.theme.text.colors.primary};
      border-top-color: transparent;
      border-radius: 50%;

      animation: ${spinnerAnimation} 1s linear infinite;
   }
`;