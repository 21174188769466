import React from "react";
import s from './Footer.module.css';
import {Container} from "react-bootstrap";

import withStr from "../../hoc/withStr";
import {getLogoAddress} from "@kovzydev/module_helpers";
import styled, {ThemeProvider} from 'styled-components'

const Footer = props => {
    return (
        <ThemeProvider theme={window.THEME_CONFIG}>
            <Wrp>
                <Container>
                    <div className={'d-flex flex-wrap align-items-center justify-content-between'}>

                        <Logo>
                            <img alt='logo' src={getLogoAddress(props.general)}/>
                        </Logo>

                        <ContactInfo>
                            <TextItemWrp>
                                <a href={'tel:' + props.general.phone}>{props.general.phone}</a>
                            </TextItemWrp>

                            <TextItemWrp>
                                <p>{props.general.address || 'მისამართი #2'}</p>
                            </TextItemWrp>

                            <TextItemWrp>
                                <a href={'mailto:' + props.general.email}>{props.general.email}</a>
                            </TextItemWrp>
                        </ContactInfo>

                        <SocialWrp>
                            <h6>{props.getStr('social_footer')}</h6>
                            <div className={'d-flex justify-content-center'}>
                                <IconWrp href={props.general.facebook_link} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f"></i>
                                </IconWrp>

                                <IconWrp href={props.general.instagram_link} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram"></i>
                                </IconWrp>
                            </div>
                        </SocialWrp>
                    </div>
                </Container>
            </Wrp>
        </ThemeProvider>
    )
}

export default withStr(Footer);


const Wrp = styled.div`
    background: ${props => props.theme.background.colors.secondary};
    padding: 20px 0;
    box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.35);

    h6{
        font-size: 1.1rem;
    }

    @media (min-width: 992px) {
        padding: 25px 0;
    }
`

const Logo = styled.div`
    text-align: center;
    width: 120px;
    margin: 0 auto 20px;

    img {
        width: 50px;
        height: auto;
        object-fit: contain;
    }

    @media (min-width: 992px) {
        justify-content: center;
        align-items: center;
        margin: 0;

        img {
            width: 70%;
        }
    }
`

const ContactInfo = styled.div`
    width: 100%;
    text-align: center;

    @media (min-width: 992px) {
        display: flex;
        width: auto;
    }
`;

const TextItemWrp = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    color: ${props => props.theme.text.colors.primary};
    margin-bottom: 10px;

    &:last-child{
        border: none;
    }

    p{
        font-size: 0.9rem;
    }

    @media (min-width: 992px) {
        border-right: 1px solid #b6b6b6;
        margin-bottom: 0;
    }
`;

const SocialWrp = styled.div`
    width: 100%;
    margin-top: 10px;

    @media (min-width: 992px) {
        width: auto;
    }
`

const IconWrp = styled.a`
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #000;
    color: white;
    margin-right: 10px;
    cursor: pointer;

    &:hover{
        color: white;
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;