import React, { Component, createRef } from 'react';
import gsap from 'gsap';
import s from './LocationModal.module.css';
import { chooseUserBranch, hideLocationModal, showLocationModal, toggleWarningLocationPopup } from "../../store/reducers/appReducer";
import CheckoutMap from '../Checkout/CheckoutMap/CheckoutMap';
import { connect } from "react-redux";
import withStr from "../../hoc/withStr";
import { compose } from "redux";
import Geocode from "react-geocode";
import { getStrHelper, toggleShopProductPopupQueryParam } from "@kovzydev/module_helpers";
import LocationSearchForm from "./LocationSearchForm/LocationSearchForm";
import { GoogleApiWrapper } from "google-maps-react";

import styled, { ThemeProvider } from 'styled-components';
import { CommonPopup, BaseBtnContainer } from '@kovzydev/module_commoncomponents';
import cx from 'classnames';
import {withRouter} from 'react-router-dom'

class LocationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputVal: '',
            submittedInputVal: '',
            addressFocused: false,
            mapMarker: {
                x: null,
                y: null,
                centerX: 41.7197567,
                centerY: 44.767644499999996,
            },
            setCurrentLocationError: '',
            mapPermissionStatus: 0 // 0 - prompt, 1 - allowed, 2 - denied
        }

        if (props.branchData) {
            this.state.inputVal = props.branchData.address;
            this.state.submittedInputVal = props.branchData.address;
            this.state.mapMarker = {
                x: props.branchData.latitude,
                y: props.branchData.longitude,
                centerX: props.branchData.latitude,
                centerY: props.branchData.longitude,
            }
        }
    }

    modalRef = createRef();
    addressSearchBtnRef = createRef();

    // lifecycles
    componentDidMount() {
        if (!this.props.showLocationModal) {
            this.hideAlertAnimation(0)
        }

        this.mapInitHandler({
            callback: () => this.chooseUserBranchHandler()
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.showLocationModal !== this.props.showLocationModal)) {
            if (this.props.showLocationModal) {
                this.showAlertAnimation();
                this.addressInputChangeHandler(this.state.submittedInputVal);
                if (this.props.branchData) {
                    this.setState({
                        inputVal: this.props.branchData.address,
                        mapMarker: {
                            x: this.props.branchData.latitude,
                            y: this.props.branchData.longitude,
                            centerX: this.props.branchData.latitude,
                            centerY: this.props.branchData.longitude,
                        }
                    })
                }
            } else {
                this.hideAlertAnimation();
            }
        }

        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.branchData) {
                this.setState({
                    inputVal: this.props.branchData.address,
                    mapMarker: {
                        x: this.props.branchData.latitude,
                        y: this.props.branchData.longitude,
                        centerX: this.props.branchData.latitude,
                        centerY: this.props.branchData.longitude,
                    }
                })
            } else {
                this.setState({
                    inputVal: '',
                    x: null,
                    y: null,
                })
            }
        }

        if (this.addressSearchBtnRef.current) {
            if ((this.state.addressFocused || this.state.inputVal) && !this.state.submittedInputVal) {
                this.addressTween = gsap.fromTo(this.addressSearchBtnRef.current, {
                    scaleX: 0.8,
                    scaleY: 0.8
                }, { scaleX: 1, scaleY: 1, duration: 1, repeat: -1, yoyo: true });
            } else {
                if (this.addressTween) {
                    gsap.killTweensOf(this.addressSearchBtnRef.current);
                }
                gsap.to(this.addressSearchBtnRef.current, { scaleX: 1, scaleY: 1 });
            }
        }

        // map update handler
        this.mapUpdatedHandler(prevState);
        // /.

        if(this.state.mapPermissionStatus !== prevState.mapPermissionStatus){
            if(this.state.mapPermissionStatus === 2){
                
                this.props.toggleWarningLocationPopup(false);
                this.props.showLocationModalMethod();
            }
        }
    }

    // /.

    getCurrentPositionOnInit = (callback) => {
        navigator.geolocation.getCurrentPosition((pos) => {
            // set map marker
            this.setMapMarker(pos.coords.latitude, pos.coords.longitude);

            Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
                response => {
                    const address = response.results[0].formatted_address;
                    this.setAddressValue(address);
                    if (callback !== null) {
                        callback();
                    }
                },
                error => {
                    console.error(error);
                }
            );
        }, () => {
            if(navigator?.permissions){
                navigator.permissions.query({name:'geolocation'}).then((result) => {
                    if(result.state === 'prompt'){
                        // show popup that asks to give us access to location
                        this.props.toggleWarningLocationPopup(true);
                        // if this user denies to give his location show location selection popup, so he can select manually
                    } else if (result.state === 'denied') {
                        this.setState({
                            mapPermissionStatus: 2
                        })
                    }
                });
            } else {
                this.setState({
                    mapPermissionStatus: 2
                })
            }
            
        });
    }

    //Map Methods
    mapInitHandler = ({
        callback = null
    }) => {
        Geocode.setApiKey('AIzaSyCtukSVhWDD4J8U6UGWIuRkSDS24psOkak');
        Geocode.setLanguage("en");

        // get current location
        if (navigator.geolocation && (!this.state.mapMarker.x && !this.state.mapMarker.y) && !this.props.disabled) {
            if(!navigator?.permissions){
                this.getCurrentPositionOnInit(callback);
                return;
            }

            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if(result.state === 'prompt'){
                    this.getCurrentPositionOnInit(callback);
                }
            });
        }
        // /.
    }

    mapUpdatedHandler = (prevState) => {
        if (prevState.submittedInputVal !== this.state.submittedInputVal) {
            if (!this.state.submittedInputVal) {
                this.setMapMarker(null, null);
            } else {
                Geocode.fromAddress(this.state.submittedInputVal).then(
                    response => {
                        const latLngObj = response.results[0].geometry.location;
                        this.setMapMarker(latLngObj.lat, latLngObj.lng, true);
                    },
                    error => {
                        console.error(error);
                    }
                )
            }

        }
    }

    onMapClicked = (mapProps, map, clickEvent) => {
        if (!this.props.disabled) {
            // props.mapMarker
            const lat = clickEvent.latLng.lat();
            const lng = clickEvent.latLng.lng();

            Geocode.fromLatLng(lat, lng).then(
                response => {
                    const address = response.results[0].formatted_address;
                    this.setAddressValue(address);
                    this.setMapMarker(lat, lng);
                    this.toggleCurrentLocationError(false);
                },
                error => {
                    console.error(error);
                }
            )

        }
    }

    toggleCurrentLocationError = (showError) => {
        if(showError){
            this.setState({
                setCurrentLocationError: 'Unable to get current location'
            });
        } else {
            this.setState({
                setCurrentLocationError: ''
            });
        }
    }

    setCurrentLocation = (callback, errorCallback) => {
        this.toggleCurrentLocationError(false);
        
        navigator.geolocation.getCurrentPosition((pos) => {
            if (navigator.geolocation && !this.props.disabled) {
                this.setMapMarker(pos.coords.latitude, pos.coords.longitude);
                Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
                    response => {
                        const address = response.results[0].formatted_address;
                        this.setAddressValue(address);
                        if(typeof callback === 'function'){
                            callback();
                        }
                    },
                    error => {
                        console.error(error);
                    }
                );

            }
        }, () => {
            if(navigator?.permissions){
                navigator.permissions.query({name:'geolocation'}).then((result) => {
                    if(result.state === 'denied'){
                        this.setState({
                            mapPermissionStatus: 2
                        })
                    }
                });
            } else {
                this.setState({
                    mapPermissionStatus: 2
                })
            }

            this.toggleCurrentLocationError(true);
            if(typeof errorCallback === 'function'){
                errorCallback();
            }
        });
    }

    setMapMarker = (lat, ln, setCenter = true, setMarker = true) => {
        let centerCoords = {};
        if (setCenter) {
            centerCoords = {
                centerX: lat,
                centerY: ln,
            }
        }

        if (setMarker) {
            this.setState({
                mapMarker: {
                    x: lat,
                    y: ln,
                    ...centerCoords,
                }
            })
        }
    }

    pinLocationChanged = () => {
        return this.state.mapMarker.x !== this.props.branchData.latitude || this.state.mapMarker.y !== this.props.branchData.longitude
    }

    submitInputValueToMaps = () => {
        this.setState((state) => ({
            submittedInputVal: state.inputVal,
        }));
    }

    setAddressValue = (value) => {
        this.setState({
            submittedInputVal: value,
            inputVal: value,
        });
    }

    onAddressFieldFocus = () => {
        this.setState({
            addressFocused: true,
        })
    }

    onAddressFieldBlur = () => {
        this.setState({
            addressFocused: false,
        })
    }
    // /.

    hideAlertAnimation = (duration = 0.3) => {
        gsap.to(this.modalRef.current, {
            opacity: 0,
            display: 'none',
            duration: duration,
        });
        document.querySelector('html').style.overflowY = '';
    }

    showAlertAnimation = (duration = 0.3) => {
        gsap.to(this.modalRef.current, {
            opacity: 1,
            display: 'block',
            duration: duration,
        });
        document.querySelector('html').style.overflowY = 'hidden';
    }

    hideModalHandler = () => {
        this.props.hideModal();
    }

    addressInputChangeHandler = (val, setAddress = true) => {
        if (setAddress) {
            this.setState({
                inputVal: val
            })
        }
    }

    chooseUserBranchHelper = (curBranchId) => {
        this.props.chooseUserBranch(
            this.state.inputVal,
            this.state.mapMarker.x,
            this.state.mapMarker.y,
            curBranchId,
        )
            .then(resStatus => {
                if (resStatus === 0) {
                    this.hideModalHandler();
                } else {
                    this.props.showLocationModalMethod();
                }
            })
    }

    chooseUserBranchHandler = () => {
        if (this.state.submittedInputVal && this.state.mapMarker.x && this.state.mapMarker.y) {
            if (this.props.branchData && (this.props.branchData.address !== this.state.submittedInputVal || this.pinLocationChanged())) {
                let curBranchId = this.props.branchData.branchid;
                this.chooseUserBranchHelper(curBranchId);
            } else if (!this.props.branchData) {
                this.chooseUserBranchHelper(null);
            }
        }
    }

    render() {

        const renderCartWarning = () => {
            if (this.props.showModalCartWarning) {
                return (
                    <p className={'text-danger'}>{this.props.getStr('branch_modal_cart_warning') || 'კალათა შეიძლება წაიშალოს ჯიგარო'}</p>
                )
            }
            return null;
        }

        const renderErrors = () => {
            const errorSources = [
                this.props.branchError,
                this.state.setCurrentLocationError
            ];

            const renderArr = [];
            errorSources.forEach(error => {
                if(error?.length > 0){
                    renderArr.push(<p className='text-danger mt-2'>{error}</p>)
                }
            });
            return renderArr;
        }

        const renderWarningPopup = () => {
            
            if (this.props.visibleWarningLocationPopup) {
                return (
                    <CommonPopup
                        hidePopup={() => {
                            this.props.toggleWarningLocationPopup(false);
                            this.props.showLocationModalMethod();
                            toggleShopProductPopupQueryParam({
                                history: this.props.history
                            });
                        }}
                        successBtnCallback={() => {
                            this.setCurrentLocation(() => {
                                this.chooseUserBranchHandler();
                                this.props.toggleWarningLocationPopup(false);
                            }, () => {
                                this.props.showLocationModalMethod();
                                this.props.toggleWarningLocationPopup(false);
                            });
                        }}
                        cancelBtnCallback={() => {
                            this.props.showLocationModalMethod();
                            this.toggleCurrentLocationError(false);
                            this.props.toggleWarningLocationPopup(false);
                        }}
                        showButtons
                    >
                        <h4 className='text-center mb-3'>{getStrHelper('location_warn_title')}</h4>
                        <p className='text-center'>{getStrHelper('location_warn_text')}</p>
                    </CommonPopup>
                )
            }
            return null;
        }

        return (
            <ThemeProvider theme={window.THEME_CONFIG}>

                {renderWarningPopup()}

                <div ref={this.modalRef} className={s.wrp}>
                    <div
                        className={s.overlay}
                        onClick={() => {
                            this.hideModalHandler();
                            toggleShopProductPopupQueryParam({
                                history: this.props.history
                            });
                        }}
                    />
                    <div className={s.modalScrollWrp}>
                        <ModalWrp>
                            <div className={`${s.modal}`}>

                                <CloseBtn onClick={() => {
                                    this.hideModalHandler();
                                    toggleShopProductPopupQueryParam({
                                        history: this.props.history
                                    });
                                }}>
                                    <i className="fas fa-times"></i>
                                </CloseBtn>

                                <div className={'d-flex flex-column h-100'}>

                                    <PopupHeader className='mb-3'>
                                        <h3>{this.props.getStr('branch_modal_title')} </h3>
                                        <p className='desc'>{this.props.getStr('branch_modal_small')}</p>
                                        {renderErrors()}
                                        {renderCartWarning()}
                                    </PopupHeader>

                                    <div className={'d-flex flex-column flex-grow-1'}>

                                        <LocationSearchForm
                                            getStr={this.props.getStr}
                                            addressInputChangeHandler={this.addressInputChangeHandler}
                                            onAddressFieldFocus={this.onAddressFieldFocus}
                                            onAddressFieldBlur={this.onAddressFieldBlur}
                                            addressSearchBtnRef={this.addressSearchBtnRef}
                                            submitInputValueToMaps={this.submitInputValueToMaps}
                                            setCurrentLocation={this.setCurrentLocation}
                                            inputVal={this.state.inputVal}
                                        />

                                        <div className={`${s.mapOuterWrp} d-flex flex-grow-1`}>
                                            <CheckoutMap
                                                getStr={this.props.getStr}
                                                mapMarker={this.state.mapMarker}
                                                setMapMarker={this.setMapMarker}
                                                addressValue={this.state.submittedInputVal}
                                                setAddressValue={this.setAddressValue}
                                                onMapClicked={this.onMapClicked}
                                                google={this.props.google}
                                            />
                                        </div>

                                        <div className={'mt-3'}>
                                            <BaseBtnContainer
                                                btnType='primary'
                                                size='md'
                                                onClicked={() => this.chooseUserBranchHandler()}
                                                className={cx(
                                                    'font-weight-bold',
                                                    'headline-font',
                                                    'px-4',
                                                    'py-2point5'
                                                )}
                                            >
                                                {getStrHelper('branch_modal_finish')}
                                            </BaseBtnContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalWrp>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    visibleWarningLocationPopup: state.app.visibleWarningLocationPopup,
    showLocationModal: state.app.showLocationModal,
    branchData: state.app.branchData,
    branchError: state.app.branchError,
    showModalCartWarning: state.app.showModalCartWarning,
})

const mapDispatchToProps = (dispatch) => ({
    toggleWarningLocationPopup: (isVisible) => dispatch(toggleWarningLocationPopup(isVisible)),
    hideModal: () => dispatch(hideLocationModal()),
    showLocationModalMethod: () => dispatch(showLocationModal()),
    chooseUserBranch: (address, latitude, longitude, curBranchId) => dispatch(chooseUserBranch(address, latitude, longitude, curBranchId))
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStr,
    GoogleApiWrapper({
        apiKey: 'AIzaSyCtukSVhWDD4J8U6UGWIuRkSDS24psOkak'
    }),
    withRouter
)(LocationModal);

const ModalWrp = styled.div`
    width: 100%;
    height: 75vh;
    min-height: 570px;
    background: ${props => props.theme.background.colors.primary};
    margin: auto;
    position: relative;
    z-index: 2;
    
    @media (min-width: 768px) {
        max-width: 650px;
        height: 80vh;
        top: 30px;
    }
`;

const PopupHeader = styled.div`
    h3{
        color: ${props => props.theme.text.colors.primary}
    }

    .desc{
        color: ${props => props.theme.text.colors.secondary}
    }
`;

const CloseBtn = styled.div`
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: ${props => props.theme.button.categorySliderButton.colors.normalBg};
    color: ${props => props.theme.button.categorySliderButton.colors.normalText};
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.26);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;

    &:hover{
        background: ${props => props.theme.button.categorySliderButton.colors.hoverBg};
        color: ${props => props.theme.button.categorySliderButton.colors.hoverText};
    }
`;