/* eslint-disable no-fallthrough */

import apiHandler from "../../../api/api";
import { Reducer, AnyAction } from 'redux'
import { popupInitialStateType as stateType } from './popupReducerTypes';
import * as popupReducerHelpers from './popupReducerHelpers';


//action constants
import { ActionConst } from "./popupReducerActionCreators";


//initial state
const initState: stateType = {
    // show of hide popup
    showPopup: false,
    // product id
    id: null,
    // available options for product
    options: null,
    // selected options from available options
    selectedOptions: null,
    amount: 1,
    price: null,
    discounted_price: null,
    name: null,
    desc: null,
    product_image: null,
    // editMode indicated if item is being edited or added for the first time
    editMode: null,
    // index is used to indicate which item from the cart is being edited
    index: null
}

//reducer
const popupReducer = (state : stateType = initState, action : AnyAction) : stateType => {
    switch (action.type) {
        // reset state to it's default to close popup
        case ActionConst.CLOSE_POPUP:
            return {
                ...initState
            };
        /* Set values for popup in state, this action handler is used with SET_SELECTED_OPTIONS and
        SET_OPTIONS, because all happen on initial render */
        case ActionConst.SHOW_POPUP:
            return {
                ...state,
                showPopup: true,
                id: action.id,
                name: action.name,
                desc: action.desc,
                price: action.price,
                discounted_price: action.discounted_price,
                amount: action.amount,
                editMode: action.editMode,
                product_image: action.product_image,
                index: action.index,
            }
        // Set fetched options into state, happens on initial render
        case ActionConst.SET_OPTIONS:
            return {
                ...state,
                options: action.options,
            }
        /* Set fetched data about selected options in state,
        this happens on inital render.
        selectedOptions are fetched from cart and then converted to be appropriate to this reducer.
        */
        case ActionConst.SET_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: action.selectedOptions,
            }
        // add ingredient to selectedOptions
        case ActionConst.ADD_INGREDIENT:
            //generate new array based on type: 0 - radio, 1-checkbox
            //key reason for this is that when we select different radio others have to be deselected on fake checkbox and from state
            const value = popupReducerHelpers.addItemToSelectedOptions({
                state,
                newOption: action.newOption
            })
            return {
                ...state,
                selectedOptions: value
            }

        // remove ingredient from selectedOptions
        case ActionConst.REMOVE_INGREDIENT:
            return {
                ...state,
                selectedOptions: popupReducerHelpers.removeItemFromSelectedOptions({
                    selectedOptions: state.selectedOptions,
                    optionToRemove: action.optionToRemove
                })
            }
        // increase amount
        case ActionConst.INCREMENT_AMOUNT:
            return popupReducerHelpers.changeAmountHelper(state, 0);
        // decrease amount
        case ActionConst.DECREMENT_AMOUNT:
            return popupReducerHelpers.changeAmountHelper(state, 1)
        case ActionConst.SET_ID:
            return {
                ...state,
                id: action.id
            }
        default:
            return state;
    }
}

export default popupReducer;


// reducer helpers

// /.