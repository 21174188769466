import apiHandler from "../../api/api";
import {clearCartUtil} from "./cartReducer/cartActionCreators";
import {getStrHelper} from "@kovzydev/module_helpers";
import getApiManager from '@kovzydev/kovzyapi';

//action constants
const CHOOSE_LANG = 'CHOOSE_LANG';
const SET_GENERAL_DATA = 'SET_GENERAL_DATA';
const SET_STRINGS = 'SET_STRINGS';
const INIT_LANGUAGES = 'INIT_LANGUAGES';
const SET_BRANCH = 'SET_BRANCH';
const SET_BRANCH_LOADING = 'SET_BRANCH_LOADING';
const SHOW_LOCATION_MODAL = 'SHOW_LOCATION_MODAL';
const HIDE_LOCATION_MODAL = 'HIDE_LOCATION_MODAL';
const SET_BRANCH_ERROR = 'SET_BRANCH_ERROR';
const TOGGLE_WARNING_LOCATION_POPUP = 'TOGGLE_WARNING_LOCATION_POPUP';


//initial state
const initState = {
    langId: 0,
    lang: 'ka',
    languages: [],
    generalData: null,
    strings: {},
    mainAlertedWasShown: false,
    branchData: null,
    mapMarker: null, // map marker should be used with branchData
    branchIsLoading: true,
    showLocationModal: false,
    showModalCartWarning: false,
    visibleWarningLocationPopup: false,
    branchError: '',
    /*
    branchData: {
       branchId,
       address,
       latitude,
       longitude,
       distance,
    }
     */

}

//reducer
const appReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_GENERAL_DATA:
            return {
                ...state,
                generalData: action.generalData
            }
        case SET_STRINGS:
            return {
                ...state,
                strings: action.strings
            }
        case INIT_LANGUAGES:
            return {
                ...state,
                languages: action.languages,
                langId: action.langId,
                lang: action.lang
            }
        case CHOOSE_LANG:
            return {
                ...state,
                langId: action.langId,
            }
        case SET_BRANCH:
            return {
                ...state,
                branchData: action.branchData,
            }
        case SET_BRANCH_LOADING:
            return {
                ...state,
                branchIsLoading: action.isLoading,
            }
        case SHOW_LOCATION_MODAL:
            return {
                ...state,
                showLocationModal: true,
                showModalCartWarning: action.showModalCartWarning,
            }
        case HIDE_LOCATION_MODAL:
            return {
                ...state,
                showLocationModal: false,
                showModalCartWarning: false,
            }
        case SET_BRANCH_ERROR:
            return {
                ...state,
                branchError: action.errorText
            }
        case TOGGLE_WARNING_LOCATION_POPUP:
            return {
                ...state,
                visibleWarningLocationPopup: action.isVisible
            }
        default:
            return state;
    }
}

export default appReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});
export const toggleWarningLocationPopup = (isVisible) => {
    return {
        type: TOGGLE_WARNING_LOCATION_POPUP,
        isVisible
    }
}

export const chooseLang = (langId) => {
    return {
        type: CHOOSE_LANG,
        langId: langId,
    }
}

export const showLocationModal = (showModalCartWarning = false) => {
    const actionObj = {
        type: SHOW_LOCATION_MODAL,
        showModalCartWarning: showModalCartWarning,
    }

    return actionObj;
}

export const hideLocationModal = () => {
    return dispatch => {
        dispatch({
            type: HIDE_LOCATION_MODAL,
        });

        getUserBranchHelper(dispatch);
    }
}

//thunks

export const initLanguages = (curLangId = null) => {
    return dispatch => {
        return getApiManager().getLanguages().promise
            .then(res => {
                let foundLangObj;

                const data = res.data;
                // find default language
                let defaultLang = data.find(el => el.default === 1);
                // /.

                // if default language is not present then use first language present in data
                if(!defaultLang){
                    defaultLang = data[0];
                }

                
                // check for language's get query parameter
                if(curLangId){
                    // if it is found, store it if it's valid
                    foundLangObj = data.find((el) => {
                        return el.id === curLangId;
                    });
                } else {
                    // if not found, set the new language id
                    foundLangObj = defaultLang
                }

                return dispatch({
                    type: INIT_LANGUAGES,
                    languages: data,
                    langId: foundLangObj.id,
                    lang: foundLangObj.lang
                });

            })
    }
}

export const getGeneralData = () => {
    return dispatch => {
        /*
        we merge responses of generaInfo and publicIntegrations
        because in the end both of this will be used together and most likely
        once on the first load
         */
        Promise.all([
            getApiManager().getGeneralInfo().promise,
            getApiManager().getPublicIntegrations().promise
        ])
        .then(res => {
            const generalDataObj = {
                ...res[0].data,
                integrations: { ...res[1].data },
            };

            window.GENERAL_INFO = generalDataObj;

            dispatch({
                type: SET_GENERAL_DATA,
                generalData: generalDataObj,
            })
        });
    }
}

export const getStrings = (langId) => {
    return dispatch => {
        return getApiManager().getFrontendStrings().promise
            .then(res => {
                dispatch({
                    type: SET_STRINGS,
                    strings: res.data,
                })
            })
    }
}

const getUserBranchHelper = (dispatch) => {
    return getApiManager().getUserBranch().promise
        .then(res => {
            let branchData = null;
            if (res.data.status === 0) {
                branchData = res.data.data
            } else {
                clearCartUtil(dispatch);
            }

            dispatch({
                type: SET_BRANCH,
                branchData: branchData,
            });

            dispatch({
                type: SET_BRANCH_LOADING,
                isLoading: false,
            });
        })
}

export const getUserBranch = () => {
    return dispatch => {

        dispatch({
            type: SET_BRANCH_LOADING,
            isLoading: true,
        });

        return getUserBranchHelper(dispatch);
    }
}

const setBranchError = (error, dispatch) => {
    dispatch({
        type: SET_BRANCH_ERROR,
        errorText: error,
    });
}

export const chooseUserBranch = (address, latitude, longitude, curBranchId = null) => {
    return dispatch => {

        const toggleLoader = () => {
            dispatch({
                type: SET_BRANCH_LOADING,
                isLoading: true,
            });

            setTimeout(() => {

                dispatch({
                    type: SET_BRANCH_LOADING,
                    isLoading: false,
                });
            }, 300);
        }

        // reset branch error to empty string
        setBranchError('', dispatch)

        return getApiManager().chooseUserBranch({
            address,
            latitude,
            longitude
        }).promise
            .then(res => {
                let branchData = null;
                if (res.data.status === 0) {

                    branchData = res.data.data
                    if(branchData.branchid !== curBranchId){
                        clearCartUtil(dispatch);
                        toggleLoader();
                    }

                } else {

                    // set branch error to a string
                    setBranchError(getStrHelper('branch_error') || 'ragac nito xdeba', dispatch)

                    clearCartUtil(dispatch);

                }

                dispatch({
                    type: SET_BRANCH,
                    branchData: branchData,
                });

                return res.data.status;
            })
    }
}

