import React from 'react';
import CartItems from "../../Navbar/Cart/CartItems";
import CartSummary from "../../Navbar/Cart/CartSummary";
import styled from 'styled-components';
import { BaseBtnContainer } from '@kovzydev/module_commoncomponents';
import { getStrHelper } from '@kovzydev/module_helpers'
import cx from 'classnames';

const OrderDetails = (props) => {

   const renderCouponButton = () => {
      if (props.couponApplied) {
         return (
            <BaseBtnContainer
               className={cx(['w-100 py-2point5 headline-font'])}
               btnType='primary'
               size='lg'
               type="submit"
               onClicked={props.handleCouponRemoveClicked}
            >
               {getStrHelper('coupon_remove') ?? 'წაშალე'}
            </BaseBtnContainer>
         )
      }
      return (
         <BaseBtnContainer
            className={cx(['w-100 py-2point5 headline-font'])}
            btnType='primary'
            size='lg'
            type="submit"
            onClicked={props.handleCouponApplyClicked}
         >
            {getStrHelper('coupon_use') ?? 'გამოიყენე'}
         </BaseBtnContainer>
      )
   }

   const renderCouponSection = () => {
      if(props.cartData?.length === 0){
         return null;
      }

      return (
         <div className='form-group coupon-wrp p-4'>
            <label className='coupon-label'>{getStrHelper('coupon')  || 'კუპონი'}: <span className="text-danger">{props.couponData?.error}</span></label>
            <div className="d-flex align-items-stretch">
               <div className="mr-2 flex-grow-1">
                  <input
                     type='text'
                     disabled={props.couponApplied}
                     className='form-control h-100'
                     value={props.coupon}
                     placeholder={getStrHelper('coupon') || 'კუპონი'}
                     onChange={props.handleCouponChange}
                  />
               </div>
               <div>
                  {renderCouponButton()}
               </div>
            </div>
         </div>
      )
   }

   

   return (
      <Wrp>
         <div className='titleWrp'>
            <h5>{props.getStr('order_details')}</h5>
         </div>
         <CartItems
            getStr={props.getStr}
            onCartItemRemoved={props.onCartItemRemoved}
            onEditCartItem={props.onEditCartItem}
            cartData={props.cartData} />
         <CartSummary
            deliveryPrice={props.deliveryPrice}
            cartData={props.cartData}
            couponData={props.couponData}
            getStr={props.getStr}
            tip={props.tip}
            additionalFeesInfo={props.additionalFeesInfo}
         />
         {renderCouponSection()}
      </Wrp>
   );
}

export default OrderDetails;

const Wrp = styled.div`
   margin: 50px 0 0;
   background: ${props => props.theme.background.colors.secondary};

   .titleWrp{
      padding: 20px 30px;
      background: ${props => props.theme.background.colors.third};
      color: ${props => props.theme.text.colors.primary};
   }

   .coupon-wrp{
      border-top: 1px solid ${props => props.theme.text.colors.subtle};
   }

   .coupon-label{
      color: ${props => props.theme.text.colors.primary};
      margin-bottom: 10px;
   }
`;