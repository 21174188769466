import React, {Component} from 'react';
import {Formik} from "formik";
import CheckoutFormInputs from "./CheckoutFormInputs/CheckoutFormInputs";
import * as Yup from 'yup';
import s from './CheckoutForm.module.css';
import styled from 'styled-components';


class CheckoutForm extends Component {
    validationSchema = () => {
        const configObj = {
            firstName: Yup.string()
                .required(this.props.getStr('required')),
            lastName: Yup.string()
                .required(this.props.getStr('required')),
            phone: Yup.string()
                .required(this.props.getStr('required')),
            email: Yup.string()
                .email(this.props.getStr('invalid_email')),
            comment: Yup.string()
                .max(150, this.props.getStr('maximum_char')),
        }

        const yupObj = Yup.object().shape(configObj);

        return yupObj;
    }

    render() {


        return (
            <FormWrp className={s.formWrp}>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        street: '',
                        tip: '',
                        phone: '',
                        email: '',
                        comment: '',
                        deliveryMethod: this.props.deliveryOptions[0].id,
                        paymentMethod: this.props.paymentOptions[0].id,
                    }}
                    
                    validationSchema={() => this.validationSchema()}
                    onSubmit={(values, { setSubmitting }) => {
                        if(!this.props.cartData || this.props.cartData.length === 0){
                            return;
                        }

                        const generatedValues = {
                            ...values,
                            paymentMethod: this.props.paymentOptions[values.paymentMethod].id,
                            deliveryTime: this.props.deliveryTime,
                        }

                        this.props.onShowPopup(generatedValues);
                        setSubmitting(false);
                    }}
                >
                    {(fmk) => {
                        return (
                            <CheckoutFormInputs
                                {...fmk}

                                checkoutConfigs={this.props.checkoutConfigs}

                                deliveryTime={this.props.deliveryTime}
                                showTimePicker={this.props.showTimePicker}
                                showTimePickerHandler={this.props.showTimePickerHandler}
                                setTime={this.props.setTime}

                                mapMarker={this.props.mapMarker}
                                setMapMarker={this.props.setMapMarker}
                                addressInput={this.props.addressInput}
                                showLocationModal={this.props.showLocationModal}
                                google={this.props.google}
                                onMapClicked={this.props.onMapClicked}

                                showCalendar={this.props.showCalendar}
                                serverError={this.props.serverError}
                                inputDate={this.props.inputDate}
                                onInputDateChanged={this.props.onInputDateChanged}
                                onDeliveryChangedHandler={this.props.onDeliveryChangedHandler}
                                onTipChangedHandler={this.props.onTipChangedHandler}
                                deliveryOptions={this.props.deliveryOptions}
                                paymentOptions={this.props.paymentOptions}

                                getStr={this.props.getStr}
                                generalData={this.props.generalData}
                                countCartTotalWithCoupon={this.props.countCartTotalWithCoupon}
                            />
                        )
                    }
                    }
                </Formik>
            </FormWrp>
        );
    }
}

export default CheckoutForm;

const FormWrp = styled.div`
    background: ${props => props.theme.background.colors.secondary};
    padding: 30px;
    margin: 50px 0;
`;