import React, {Component, createRef} from 'react';
import s from './PhoneValidator.module.css';
import gsap from 'gsap';
import styled from 'styled-components';
import { BaseBtnContainer } from '@kovzydev/module_commoncomponents';
import { getStrHelper } from '@kovzydev/module_helpers';

class PhoneValidator extends Component {
   popupWrpRef = createRef();
   popupBoxRef = createRef();

   state={
      verificationCode: '',
   }

   componentDidMount(){
      this.initPopup();
   }

   componentDidUpdate(prevProps) {
      if(prevProps.showPhoneValidatorPopup !== this.props.showPhoneValidatorPopup){
         if(this.props.showPhoneValidatorPopup){
            this.showPopupAnimation();
            
            this.setState({
               verificationCode: '',
            });
         } else {
            this.closePopupAnimation();
         }
      }
   }

   onVerificationCodeChanged = (e) => {
      this.setState({
         verificationCode: e.target.value,
      })
   }


   // popup show/hide methods
   // popup SHOW/HIDE animation methods
   initPopup = () => {
      this.props.onClosePopup();
      //init styles for popup reveal animation
      const popupWrpRef = this.popupWrpRef.current;
      const popupBoxRef = this.popupBoxRef.current;

      gsap.set(popupWrpRef, {display: 'none', opacity: 0});
      gsap.set(popupBoxRef, {opacity: 0, scaleX: 0.8, scaleY: 0.8, x: '-50%', y: '-50%'});
   }

   showPopupAnimation = () => {
      const popupWrpRef = this.popupWrpRef.current;
      const popupBoxRef = this.popupBoxRef.current;

      //animation wrapper
      gsap.set(popupWrpRef, {display: 'block'});
      gsap.to(popupWrpRef, {opacity: 1, duration: 0.2});

      //animation popupbox
      gsap.to(popupBoxRef, {opacity: 1, scaleX: 1, scaleY: 1, duration: 0.2});
   }

   closePopupAnimation = () => {
      const popupWrpRef = this.popupWrpRef.current;
      const popupBoxRef = this.popupBoxRef.current;

      //animation wrapper
      gsap.to(popupWrpRef, {opacity: 0, duration: 0.2, onComplete: () => {
            gsap.set(popupWrpRef, {display: 'none'});
         }
      });

      //animation popupbox
      gsap.to(popupBoxRef, {opacity: 0, scaleX: 0.8, scaleY: 0.8, duration: 0.2});
   }
   ///.

   render() {
      return (
         <Wrp ref={this.popupWrpRef}>
            <div className='darkBg' onClick={this.props.onClosePopup}></div>
            <div className='box' ref={this.popupBoxRef}>
               <form onSubmit={(e) => {
                  e.preventDefault();
                  this.props.onSubmitHandler(this.state.verificationCode);
               }}>
                  <h3 className={`mb-2 title`}><label htmlFor="">{this.props.getStr('verif_title')}</label></h3>
                  <p className={`mb-3 d-block par`}>{this.props.getStr('verif_code_text')}</p>
                  <input
                     type="text"
                     className={'form-control'}
                     value={this.state.verificationCode}
                     onChange={this.onVerificationCodeChanged}/>
                  <div className={'mt-3'}>
                     <BaseBtnContainer
                        btnType={'primary'}
                        size='md'
                        className='px-3 py-2point5 box-shadow headline-font'
                     >
                        {getStrHelper('submit')}
                     </BaseBtnContainer>
                  </div>
               </form>
            </div>
         </Wrp>
      );
   }
}

export default PhoneValidator;

const Wrp = styled.div`
position: fixed;
left: 0;
top: 0;
z-index: 9;

&, .darkBg{
   width: 100%;
   height: 100%;
}

.darkBg{
   background: rgba(0,0,0,0.5);
   position: absolute;
}

.box{
   width: 80%;
   max-width: 500px;
   background: ${props => props.theme.background.colors.secondary};

   position: absolute;
   left: 50%;
   top: 50%;

   padding: 30px;
}

.title{
   color: ${props => props.theme.text.colors.primary};
}

.par{
   color: ${props => props.theme.text.colors.primary};
}
`;