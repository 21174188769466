import React from 'react';
import styled from 'styled-components';

const Radio = (props) => {
   return (
      <Wrp>
         <div onClick={props.onChangeHandler} className={'d-flex align-items-center'}>
            <input type="radio" name={props.name} checked={props.isChecked} onChange={() => {}} className={'d-none'}/>
            <FakeCheckbox className={props.isChecked ? 'checked' : ''}>
               <i className='fas fa-check icon'/>
            </FakeCheckbox>
            <label className='text'>{props.name}</label>
         </div>
      </Wrp>
   );
};

export default Radio;

const Wrp = styled.div`
   .text{
      font-size: 0.9rem;
      color: ${props => props.theme.text.colors.primary};
   }
   
   .icon {
      display: none;
      color: ${props => props.theme.text.colors.primary};
      font-size: 12px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
`;

const FakeCheckbox = styled.div`
   width: 20px;
   height: 20px;
   border: 1px solid ${props => props.theme.text.colors.primary};
   border-radius: 50%;
   margin-right: 5px;
   position: relative;
   
   &.checked .icon{
      display: block;
  }
`;