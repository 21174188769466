import React from 'react';
import {Map, Marker} from 'google-maps-react';
import s from './CheckoutMap.module.css';

class CheckoutMap extends React.PureComponent {

    render() {

        const markerJSX = () => {
            if (this.props.mapMarker.x && this.props.mapMarker.y) {
                return <Marker position={{lat: this.props.mapMarker.x, lng: this.props.mapMarker.y}}/>
            }
            return null
        }

        return (
            <div className={'h-100 d-flex flex-column'}>
                <div className={`position-relative ${s.mapWrp}`}>
                    <Map
                        onClick={this.props.onMapClicked}
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative'
                        }}
                        containerStyle={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0'
                        }}
                        google={this.props.google}
                        initialCenter={{
                            lat: this.props.mapMarker.centerX,
                            lng: this.props.mapMarker.centerY,
                        }}
                        center={{
                            lat: this.props.mapMarker.centerX,
                            lng: this.props.mapMarker.centerY,
                        }}
                        zoom={14}
                    >
                        {markerJSX()}
                    </Map>
                </div>
            </div>
        );
    }
}

export default (CheckoutMap);