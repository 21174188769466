import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {imagePrepath, linkWithLang} from "@kovzydev/module_helpers";
import s from "./CategoryItem.module.css";
import React from "react";

const CategoryItem = (props) => {
    return (
        <Col lg={3} xs={6} className={`mb-4 ${s.column}`}>
            <Link to={linkWithLang(`/shop?category=${props.name}`)}>
                <div className={`${s.wrp} `}>
                    <div className={`${s.inner}`}>
                        <img
                            src={imagePrepath(props.image_name)}
                            className={s.img}
                            alt="category"
                        />
                        <div className={s.textWrp}>
                            <h3 className={s.title}>{props.name}</h3>
                        </div>
                    </div>
                </div>
            </Link>
        </Col>
    )
}

export default CategoryItem;