import { optionsIsCollection } from '@kovzydev/module_helpers';
import { popupInitialStateType, ProductPopup, ReducerHelpersTypes, SelectedOptionsType } from './popupReducerTypes';

/*
This helpers assist operations in reducer handlers
*/

export const changeAmountHelper : ReducerHelpersTypes.changeAmountHelperType = (state, operation) => {
    if (state?.amount) {
        let newAmount = state.amount;

        if (operation === 0) {
            // 0 means increment
            newAmount += 1;
        } else if (operation === 1 && state.amount > 0) {
            // 1 means decrement
            newAmount -= 1;
        }

        return {
            ...state,
            amount: newAmount,
        }
    }
    return state;
}


const generateNewSelectedOptionsSingleProduct : ReducerHelpersTypes.getNewSelectedOptionsObjSingleProduct = ({
    state,
    newOption
}) => {
    if (!(state?.selectedOptions?.options)) {
        return state.selectedOptions
    }
    // generating initial structure of newSelectedOptions
    let newSelectedOptions: typeof state.selectedOptions = {
        options: []
    }
    // /.
    if (newOption.type === 0) {
        /* Options type 0 means that only one pack of this option can be selected */
        newSelectedOptions.options = state.selectedOptions.options.filter(
            option => option.optId !== newOption.optId
        );
    } else {
        /* options type is not 0, so we simply spread old selectedOptions array
        for immutability purposes */
        newSelectedOptions.options = [...state.selectedOptions.options];
    }

    // add new selected options
    newSelectedOptions.options.push({
        optId: newOption.optId,
        packId: newOption.packId,
    });

    return newSelectedOptions;
}

const immutablyModifyCollectionSelectedOptions : ReducerHelpersTypes.immutablyModifyCollectionSelectedOptions = ({
    newOption,
    collectionChildToModify
}) => {

    // filter customizables of this child
    if (newOption.type === 0) {
        /* Options type 0 means that only one pack of this option can be selected */
        return collectionChildToModify.customizables.filter(option => option.optId !== newOption.optId)
    }
    // /.

    // 
    return [...collectionChildToModify.customizables];
    // /.
}

const immutablyCopyCollectionOptionsChildren = ({
    selectedOptions,
    newOption
}: {
    selectedOptions: popupInitialStateType['selectedOptions'],
    newOption: ReducerHelpersTypes.newOptionArgType
}) => {

    // THIS IS USED TO VALIDATE ARGUMENTS
    if (!selectedOptions?.children || !(newOption?.childIndex || newOption?.childIndex === 0)) {
        return null;
    }
    //

    let newSelectedOptions: typeof selectedOptions = {
        children: [...selectedOptions.children]
    }

    newSelectedOptions.children[newOption.childIndex] = {
        ...newSelectedOptions.children[newOption.childIndex]
    }

    return newSelectedOptions;
};

const generateNewSelectedOptionsCollection : ReducerHelpersTypes.getNewSelectedOptionsObjCollection = ({
    state,
    newOption
}) => {

    // THIS IS USED TO VALIDATE ARGUMENTS
    if (!(state?.selectedOptions?.children)) {
        return state.selectedOptions
    }
    //

    let newSelectedOptions = immutablyCopyCollectionOptionsChildren({
        selectedOptions: state.selectedOptions,
        newOption
    });


    if (newSelectedOptions?.children && (newOption?.childIndex || newOption?.childIndex === 0)) {
        /* immutably modify collection child's options,
        if type is 0 it means that the option is RADIO(not CHECKBOX), then only one pack/option is allowed.
        if checkbox, currently selected pack of current option type will be replace by newOption's data
        */
        const modifiedOptions = immutablyModifyCollectionSelectedOptions({
            newOption,
            collectionChildToModify: newSelectedOptions.children[newOption.childIndex]
        });

        // add new option to immutably copied collection's child's options
        modifiedOptions.push({
            optId: newOption.optId,
            packId: newOption.packId,
        });

        // store modifed options
        newSelectedOptions.children[newOption.childIndex].customizables = modifiedOptions;
    }

    // return finally generated object
    return newSelectedOptions;
}

export const addItemToSelectedOptions : ReducerHelpersTypes.getNewSelectedOptionsObjType = ({
    state,
    newOption
}) => {

    let newSelectedOptions: typeof state.selectedOptions | null = null;
    if (state?.selectedOptions?.options) {
        // SINGLE PRODUCT OPTIONS
        newSelectedOptions = generateNewSelectedOptionsSingleProduct({
            state: state,
            newOption: newOption,
        });

    } else if (state?.selectedOptions?.children && Number.isInteger(newOption.childIndex)) {
        // COLLECTION OPTIONS
        newSelectedOptions = generateNewSelectedOptionsCollection({
            state: state,
            newOption: newOption,
        });
    }
    // returning new selected options object
    return newSelectedOptions ?? state.selectedOptions
}

export const removeItemFromSelectedOptions : ReducerHelpersTypes.removeItemFromSelectedOptions = ({
    optionToRemove,
    selectedOptions
}) => {

    if(selectedOptions?.options){
        // SINGLE PRODUCT
        // creating new object, with optionToRemove removed from options
        return {
            options: selectedOptions.options.filter(option => {
                return !(option.packId === optionToRemove.packId && option.optId === optionToRemove.optId)
            }) 
        };
        
    }
    else if (selectedOptions?.children){
        // COLLECTION
        // creating new object, with optionToRemove removed from options
        return {
            children: selectedOptions.children.map((child, index) => {
                if(index === optionToRemove.childIndex){
                    return {
                        ...child,
                        customizables: child.customizables.filter(option => {
                            // filter returns array elements whose optId and packId don't match
                            return !(option.optId === optionToRemove.optId && option.packId === optionToRemove.packId)
                        })
                    }
                }

                return child;
            }),
        }
    }

    return selectedOptions;
}