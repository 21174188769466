//action constants
import apiHandler from "../../api/api";

const INIT_HOMEPAGE = 'INIT_HOMEPAGE';
const SET_BRANCHES = 'SET_BRANCHES';

//initial state
const initState = {
    featuredProducts: [],
    categories: [],
    specialOffers: [],
    slides: [],
    blog: [],
    gallery: [],
    branches: [],
    init: false,
}

//reducer
const homePageReducer = (state = initState, action) => {
    switch (action.type) {
        case INIT_HOMEPAGE:
            return {
                ...state,
                featuredProducts: action.featuredProducts,
                categories: action.categories,
                specialOffers: action.specialOffers,
                slides: action.slides,
                blog: action.blog,
                gallery: action.gallery,
                branches: action.branches,
                init: true,
            }
        case SET_BRANCHES:
            return {
                ...state,
                branches: action.branches
            }
        default:
            return state;
    }
}

export default homePageReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});


//thunk creators
export const initHomepage = () => {
    return dispatch => {
        return Promise.all([
            apiHandler('getCategories'),
           ]
        )
        .then(res => {
            const dataObj = {
                categories: res[0],
            }

            dispatch({type: INIT_HOMEPAGE, ...dataObj });
        })
    }
}

