import React from "react";
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import s from './DesktopNavbar.module.css';
import { BaseBtnContainer } from "@kovzydev/module_commoncomponents";
import { Link } from "react-router-dom";
import { getLogoAddress, getStrHelper, isMexicanaSite, linkWithLang } from "@kovzydev/module_helpers";
import { getRegistrationData } from '@kovzydev/module_manager';
import styled from 'styled-components';

const DesktopNavbar = (props) => {

    const chosenLanguage = props.languages.find(el => el.id === props.langId).name;
    const otherLanguages = props.languages.map((el, i) => {
        if (el.id !== props.langId) {
            return <div key={i} className={'dropdown-item'} onClick={() => props.chooseLangHandler(el.id)}>{el.name}</div>
        }
        return null;
    })

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <LanguageDropdownToggle className='headline-font'
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </LanguageDropdownToggle>
    ));

    const renderMessage = () => {
        if (!props.branchData) {
            return (
                <div className={'position-relative w-100'}>
                    <LocationMessageArrow className='fas fa-sort-up' />
                    <LocationMessageWrp>
                        <LocationMessage>
                            {props.getStr('branch_modal_title')}
                        </LocationMessage>
                    </LocationMessageWrp>
                </div>
            )
        }
        return null;
    }

    const getCustomLogo = () => {
        let logo = getLogoAddress(props.generalData);
        return logo;
    }

    return (
        <MainWrp>
            <div className='desktopNavbar'>
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={12}>
                            <Row className={'justify-content-between'}>


                                <div className={s.LogoWrp}>
                                    <Col>
                                        <div className={s.LogoInnerWrp}>
                                            <Link to={linkWithLang('/')}>
                                                <img src={getCustomLogo()} className={s.Logo} alt="logo" />
                                            </Link>
                                        </div>
                                    </Col>
                                </div>

                                <Col md={7}>
                                    <div className={'d-flex align-items-center'}>
                                        <NavItemsWrp>
                                            <NavItemsInnerWrp>


                                                <NavItem className='navItem font-weight-bold headline-font'>
                                                    <Link to={linkWithLang('/')}>{props.getStr('nav_home')}</Link>
                                                </NavItem>

                                                <NavItem className='navItem font-weight-bold headline-font'>
                                                    <BaseBtnContainer
                                                        btnType='primary'
                                                        size='md'
                                                        rounded
                                                        linkPath={linkWithLang('/categories')}
                                                        className='py-2point5 px-3 border-none box-shadow'
                                                    >{getStrHelper('nav_shop')}</BaseBtnContainer>
                                                </NavItem>

                                                {getRegistrationData('getNavigations').map((navigationState, i) => {
                                                    const nav = navigationState();
                                                    return nav.map(({ to, label }, j) => {
                                                        return (
                                                            <NavItem key={`${i}_${j}`} className='font-weight-bold headline-font'>
                                                                <Link to={to}>{label}</Link>
                                                            </NavItem>
                                                        );
                                                    });
                                                })}

                                                <NavItem className='navItem font-weight-bold headline-font'>
                                                    <Link to={linkWithLang('/contact')}>{props.getStr('nav_contact')}</Link>
                                                </NavItem>

                                            </NavItemsInnerWrp>
                                        </NavItemsWrp>
                                    </div>
                                </Col>

                                <Col md={2}>
                                    <div className={`d-flex justify-content-end align-items-center h-100 cartWrp`}>

                                        <Dropdown className={'mr-3'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                                {chosenLanguage}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {otherLanguages}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {!isMexicanaSite()
                                            ? (<CartIconWrp className='d-flex align-items-center'
                                                onClick={props.branchData ? props.onCartBtnClicked : props.showLocationModal}>
                                                <div className={'position-relative'}>
                                                    <CartAmount className='d-flex align-items-center justify-content-center'>
                                                        {props.cartItemsAmount}
                                                    </CartAmount>
                                                    <CartIcon className='fas fa-shopping-cart' />
                                                </div>
                                            </CartIconWrp>)
                                            : null
                                        }


                                        <div>
                                            <div className={`ml-2 locationIcon`} onClick={props.showLocationModal}>
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>

                                            {renderMessage()}

                                        </div>

                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        </MainWrp>
    )
}

export default DesktopNavbar;

const MainWrp = styled.div`
    @media (max-width: 1100px) {
        display: none;
    }

    .desktopNavbar{
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;

        background: ${props => props.theme.background.colors.secondary};
        box-shadow: 0 0 15px -5px #000;

        z-index: 4;
    }

    .locationIcon{
        margin-right: 10px;
        color: ${props => props.theme.text.colors.primary};
        font-size: 25px;
        cursor: pointer;

        &:hover{
            opacity: 0.6;   
        }
    }
`;

const LanguageDropdownToggle = styled.div`
    color: ${props => props.theme.text.colors.primary};
    border: 1px solid ${props => props.theme.text.colors.primary};
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
`;

const CartIconWrp = styled.div`
    transition: opacity .2s ease;
    cursor: pointer;

    &:hover{
        opacity: 0.6;
    }
`;

const CartAmount = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -10px;
    right: 2px;
    background: #777;
    color: white;
`

const CartIcon = styled.i`
    margin-right: 10px;
    color: ${props => props.theme.text.colors.primary};
    font-size: 25px;
    cursor: pointer;
`

// navitems
const NavItemsWrp = styled.nav`
    padding-top: 27.5px;
    padding-bottom: 27.5px;
`;

const NavItemsInnerWrp = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
`

const NavItem = styled.div`
    list-style-type: none;
    margin-right: 18px;
    color: ${props => props.theme.text.colors.primary};
    line-height: normal;
`


// location message
const LocationMessageWrp = styled.div`
    position: absolute;
    top: 9px;
    right: -9px;
    width: 200px;
    border-radius: 10px;
`;

const LocationMessage = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: #707070;
    color: white;
    font-size: 1rem;
`;

const LocationMessageArrow = styled.i`
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 27px;
    color: #707070;
    z-index: 2;
`;