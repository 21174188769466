import React, { Component, createRef } from 'react';
import TitleBanner from "../common/TitleBanner/TitleBanner";
import bannerImg from '../../assets/images/5.jpg'
import { Col, Container, Row } from "react-bootstrap";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import PhoneValidator from "./PhoneValidator/PhoneValidator";
import OrderDetails from "./OrderDetails/OrderDetails";
import { withPopup } from "@kovzydev/module_productpopup";
import { connect } from "react-redux";
import apiHandler from "../../api/api";
import getApiHandler from '@kovzydev/kovzyapi'

import { checkoutFormSubmit, clearCart, sendVerifCode, setCouponData, removeCoupon } from "../../store/reducers/cartReducer/cartActionCreators";
import gsap from 'gsap';
import Loader from "../common/Loader/Loader";
import withStr from "../../hoc/withStr";
import { showLocationModal } from "../../store/reducers/appReducer";
import { compose } from "redux";
import { GoogleApiWrapper } from "google-maps-react";
import CheckoutNotAvaliable from "./CheckoutNotAvailable/CheckoutNotAvailable";
import styled, { ThemeProvider } from 'styled-components'
import { imagePrepath, getStrHelper } from '@kovzydev/module_helpers';
import * as CartAC from '../../store/reducers/cartReducer/cartActionCreators';
import { CommonPopup } from '@kovzydev/module_commoncomponents';
import Geocode from "react-geocode";
import { parseJwt } from '../../helpers/helpers'


const CHECKOUT_CONFIG_STRUCT = [
    'comment',
    'comment_shown',
    'firstname',
    'firstname_shown',
    'table_number',
    'tables_count',
    'table_number_shown',
    'phone_shown'
];
class Checkout extends Component {
    constructor(props) {
        super(props);

        const currentDate = new Date();
        let minutes = String(currentDate.getMinutes());
        minutes = minutes.length === 1 ? '0' + minutes : minutes;

        const currentTime = currentDate.getHours() + ':' + minutes;


        this.state = {
            deliveryOptions: [],
            paymentOptions: [],
            paymentAndDeliveryLoaded: false,

            additionalFeesInfo: null,

            chosenDeliveryMethod: -1,
            showPhoneValidatorPopup: false,
            formData: null,
            inputDate: new Date(),

            deliveryTime: currentTime,
            showTimePicker: false,

            coupon: '',
            couponApplied: false,
            showCouponCheckPopup: false,
            generatedFormValues: null,

            checkoutConfigs: null,
            tip: null,

            serverError: '',
            init: false,
        }

        if (this.props.branchData) {
            this.state.mapMarker = {
                x: props.branchData.latitude,
                y: props.branchData.longitude,
                centerX: props.branchData.latitude,
                centerY: props.branchData.longitude,
            }
        }


    };

    checkoutSuccessRef = createRef();
    checkoutFormRef = createRef();

    componentDidMount() {
        // scrolling to top when somebody visits this page
        // this.scrollToTop(this.checkoutFormRef.current);
        window.scroll(0, 0);
        this.setDeliveryAndPaymentMethods();
        if (this.props.couponData?.code) {
            this.props.setCouponData(this.props.couponData.code)
        }

        this.getAdditionalFeesInfo();

        getApiHandler().getCheckoutConfigs({ orderType: 0 }).promise.then(res => {
            if (res?.data) {
                this.setState({
                    checkoutConfigs: res.data
                });
            } else {
                console.error('Invalid checkout config', res?.data);
            }
        });

    }

    componentDidUpdate(prevProps) {
        if (!this.state.init && this.state.deliveryOptions.length > 0 && this.state.paymentOptions.length > 0) {
            gsap.set(this.checkoutSuccessRef.current, { display: 'none', opacity: 0 });
            this.setState({
                init: true,
            })
        }

        if (prevProps.cartData !== this.props.cartData) {
            if (this.props.couponData?.code) {
                this.props.setCouponData(this.props.couponData.code)
            }
        }
    }

    getAdditionalFeesInfo = async () => {
        // get data from api
        const res = await getApiHandler().getAdditionalFeesInfo({ orderType: 0 }).promise;

        // store data from api in state somewhere
        this.setState({ additionalFeesInfo: res.data });

        // pass the stored data to the cart summary using context or redux

        // render the data inside cart summary
    }

    handleCouponChange = (e) => {
        if (!this.state.couponApplied) {
            const value = e.target.value;
            this.setState({
                coupon: value
            })
        }
    }

    handleCouponApplyClicked = () => {
        this.props.setCouponData(this.state.coupon)
            .then((status) => {
                if (status === 1) {
                    this.setState({
                        couponApplied: true
                    })
                }
            })
            .catch(console.error);
    }

    handleCouponRemoveClicked = () => {
        this.props.removeCoupon()
        this.setState({
            couponApplied: false,
            coupon: ''
        })
    }

    getAddressError = (value) => {
        let error = '';
        if (!value || value.length === 0) {
            error = 'Required';
        }
        return error;
    }

    addressChangeHandler = (value) => {
        this.setState({
            addressInput: {
                ...this.state.addressInput,
                value: value,
                error: this.getAddressError(value),
            }
        })
    }

    addressBlurHandler = (value) => {
        this.setState({
            addressInput: {
                ...this.state.addressInput,
                touched: true,
                error: this.getAddressError(value),
            }
        })
    }

    setMapMarker = (lat, ln, setCenter = false) => {
        let centerCoords = {};
        if (setCenter) {
            centerCoords = {
                centerX: lat,
                centerY: ln,
            }
        }

        this.setState({
            mapMarker: {
                x: lat,
                y: ln,
                ...centerCoords,
            }
        })
    }

    showTimePicker = (show) => {
        this.setState({
            showTimePicker: show,
        });
    }

    setTime = (time) => {
        this.setState({
            deliveryTime: time,
        })
    }

    showCalendar = () => {
        const deliveryMethod = parseInt(this.state.chosenDeliveryMethod);
        const res = this.state.deliveryOptions.findIndex((el) => {
            return el.type === 0 && el.id === deliveryMethod
        });

        return res !== -1;
    }

    /** 
     * Converts array of JWT tokens representing delivery types into array of object literals
     * @returns {Array}
    */
    transformDeliveronArrayToFrontendCompatible = (deliveronArr, showOnlyBestPrice = false) => {
        const transformedArr = deliveronArr.map((deliveryTypeJWT, i) => {
            const deliveryType = parseJwt(deliveryTypeJWT)

            const newObj = {
                id: i,
                deliveron_companyId: deliveryType.companyId,
                price: deliveryType.price,
                name: deliveryType?.name ?? 'no name provided',
                type: deliveryType.type,
                companyName: deliveryType.companyName,
                jwt: deliveryTypeJWT
            }

            if(deliveryType?.dividedCost){
                newObj.price = deliveryType?.dividedCost?.clientCost
            }

            if (deliveryType?.id) {
                newObj.deliveron_id = deliveryType.id
            } else {
                /**
                 * if we don't have deliveron_id that means that this is
                 * dynamically calculated delivery option, as a result
                 * we should render "companyName", instead of just "name"
                */
                newObj.name = deliveryType?.companyName ?? 'no company name provided'
            }

            return (newObj);
        });

        return showOnlyBestPrice ? transformedArr.slice(0, 1) : transformedArr;
    }




    /**
     * Transforms delivery data from api into frontend compatible structure
     * @param {Array<Record>} data
     * @returns {Array<{
     *  id: number,
     *  name: string,
     *  type: number,
     *  price: number,
     *  free_after: number,
     * } |
     *  {
     * id: number;
     * deliveron_companyId: number;
     * price: number;
     * name: string;
     * type: number;
     * companyName: string;
     * jwt: string;
     * }>}
     */
    handleDeliveryTypeResponse = (data) => {
        let deliveryOptionsArr = data;
        if (deliveryOptionsArr?.deliveronDeliveryTypes) {
            deliveryOptionsArr = this.transformDeliveronArrayToFrontendCompatible(deliveryOptionsArr.deliveronDeliveryTypes)
        } else if (deliveryOptionsArr?.deliveronDeliveryBestPrice) {
            deliveryOptionsArr = this.transformDeliveronArrayToFrontendCompatible(deliveryOptionsArr.deliveronDeliveryBestPrice, true);
        }
        else {
            deliveryOptionsArr = deliveryOptionsArr.map(el => ({
                id: el.id,
                name: el.name,
                type: el.type,
                price: el.price,
                free_after: el.free_after,
            }));
        }
        return deliveryOptionsArr;
    }

    setDeliveryAndPaymentMethods = () => {
        Promise.all([
            getApiHandler().getPaymentTypes({ EnabledType: 'enabled' }).promise,
            getApiHandler().getDeliveryTypes().promise,
        ])
            .then(res => {
                const paymentOptionsArr = res[0].data.map(el => ({
                    id: el.id,
                    name: el.name,
                    type: el.type
                }));

                let deliveryOptionsArr = this.handleDeliveryTypeResponse(res[1].data)

                // !! IMPORTANT !!
                /*
                When submitting form data for checkout make sure to take out the jwt prop from
                delivery object and submit it with deliveryToken key name 
                */

                this.setState({
                    paymentOptions: paymentOptionsArr,
                    deliveryOptions: deliveryOptionsArr,
                    paymentAndDeliveryLoaded: true,
                });

            })
    }

    onClosePopup = () => {
        this.setState({
            showPhoneValidatorPopup: false,
            formData: null,
        })
    }

    onShowPopup = (dataObj) => {
        // chose delivery method object
        const deliveryMethodObj = this.state.deliveryOptions.find(el => el.id === this.state.chosenDeliveryMethod);
        if (deliveryMethodObj) {
            dataObj.companyId = deliveryMethodObj.deliveron_companyId;
            dataObj.id = deliveryMethodObj.deliveron_id;
            dataObj.deliveryPrice = this.getDeliveryPrice();
            // type
            dataObj.type = deliveryMethodObj.type;
            // companyName
            dataObj.companyName = deliveryMethodObj.companyName;
            if(deliveryMethodObj?.jwt && typeof deliveryMethodObj.jwt === 'string'){
                dataObj.deliveryToken = deliveryMethodObj.jwt;
            }
        }
        // /.

        dataObj.deliveryScheduled = this.state.inputDate;
        dataObj.street = this.props.branchData.address;
        dataObj.coupon_code = this.state.coupon;

        this.props.sendVerifCode(dataObj.phone)

        this.setState({
            showPhoneValidatorPopup: true,
            formData: dataObj,
            generatedFormValues: null
        });
    }

    showCouponCheckPopup = (dataObj) => {
        if (this.state.coupon && !this.state.couponApplied) {
            this.setState({
                showCouponCheckPopup: true,
                generatedFormValues: dataObj
            })
        } else {
            this.onShowPopup(dataObj);
        }
    }

    hideCouponCheckPopup = () => {
        this.setState({
            showCouponCheckPopup: false
        })
    }

    onDeliveryChangedHandler = (val, setFieldValue) => {
        setFieldValue('deliveryMethod', val);
        this.setState({
            chosenDeliveryMethod: parseInt(val)
        });
    }

    onTipChangedHandler = (val, setFieldValue) => {
        setFieldValue('tip', val);
        this.setState({
            tip: val
        });
    }

    scrollToTop = (elem) => {
        const y = elem.getBoundingClientRect().top + window.scrollY - 100;
        window.scroll({
            top: y,
            behavior: 'smooth'
        });
    }

    redirectToInvoice = (res) => {
        if (res.invoice) {
            if (process.env.REACT_APP_APIManager_HOSTNAME) {
                window.open(process.env.REACT_APP_APIManager_HOSTNAME + res.invoice, '_blank')
            } else {
                const urlArr = window.location.href.split('/');
                window.open(urlArr[0] + '//' + urlArr[2] + res.invoice, '_blank')
            }
        }
    }

    redirectPost = (url, data) => {
        if (!data) {
            window.location.href = url;
            return;
        }

        const form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'post';
        form.action = url;
        for (const name in data) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = data[name];
            form.appendChild(input);
        }
        form.submit();
    }

    redirectToLibertyPayment = (res = null) => {
        if (!res) {
            throw new Error('Invalid Response Data')
        }

        const liberty_data = res.liberty_data

        if (!liberty_data) {
            console.error('Liberty Data', liberty_data)
            throw new Error('Invalid Data for liberty')
        }

        const form = document.createElement('form');
        form.setAttribute('action', res.redirect.url)
        form.setAttribute('method', 'post')

        const inputs = {
            merchant: liberty_data.merchant,
            ordercode: liberty_data.ordercode,
            amount: liberty_data.amount,
            currency: liberty_data.currency,
            description: liberty_data.description,
            lng: liberty_data.lng,
            // testmode: liberty_data.testmode,
            // successurl: liberty_data.successurl,
            // cancelurl: liberty_data.cancelurl,
            // errorurl: liberty_data.failurl,
            // callbackurl: liberty_data.callback,
            check: liberty_data.check
        }

        for (const name in inputs) {
            const inputElement = document.createElement('input');
            inputElement.setAttribute('type', 'hidden');
            inputElement.setAttribute('name', name);
            inputElement.setAttribute('value', inputs[name]);
            form.appendChild(inputElement);
        }

        document.body.appendChild(form)

        form.submit()
    }

    onSubmitHandler = (verifCode) => {
        this.setState({
            serverError: ''
        });


        this.props.onFormSubmit(this.state.formData, verifCode, this.showCalendar(), this.state.mapMarker)
            .then(res => {

                res = res.data;

                if (res.status === 0 && res.message === 'OK') {

                    this.setState({
                        showPhoneValidatorPopup: false,
                        formData: null,
                        couponApplied: false,
                        coupon: '',
                    });

                    this.props.clearCart();

                    gsap.to(this.checkoutSuccessRef.current, { display: 'flex', opacity: 1 });
                    setTimeout(() => {
                        gsap.to(this.checkoutSuccessRef.current, {
                            display: 'none', opacity: 0,
                            onComplete: () => {
                                // this.redirectToInvoice(res);
                            }
                        });
                    }, 2000);

                } else if (res.status === 1 && res.hasOwnProperty('redirect') && res.redirect.hasOwnProperty('url')) {
                    // this.redirectToInvoice(res);

                    if (res?.liberty_data) {
                        this.redirectToLibertyPayment(res)
                        return;
                    }

                    this.redirectPost(res.redirect.url, res.redirect?.POST);
                } else {
                    this.setState({
                        showPhoneValidatorPopup: false,
                        formData: null,
                        serverError: res.message,
                        couponApplied: false,
                        coupon: '',
                    });
                    this.scrollToTop(this.checkoutFormRef.current);
                    // this.redirectToInvoice(res);
                }
                removeCoupon();

            })
    }

    onInputDateChanged = (date) => {
        this.setState({
            inputDate: date
        })
    }

    countCartTotalPrice = () => {
        if (Array.isArray(this.props.cartData)) {
            return this.props.cartData.reduce((accum, cur) => {
                return accum + cur.price;
            }, 0);
        }
    }

    countCartTotalWithCoupon = () => {
        if (this.props.couponData?.discount) {
            return this.countCartTotalPrice() - this.props.couponData?.discount;
        }
        return this.countCartTotalPrice();
    }

    getDeliveryPrice = () => {
        let deliveryPrice = 0;
        let chosenDeliveryMethodObj = undefined;

        if (this.state.deliveryOptions.length > 0) {
            if (this.state.chosenDeliveryMethod === -1) {
                chosenDeliveryMethodObj = this.state.deliveryOptions[0];
                this.setState({
                    chosenDeliveryMethod: this.state.deliveryOptions[0].id,
                })
            } else {
                chosenDeliveryMethodObj = this.state.deliveryOptions.filter(
                    el => el.id === this.state.chosenDeliveryMethod
                )[0]
            }

            if (this.countCartTotalWithCoupon() > parseFloat(chosenDeliveryMethodObj.free_after)) {
                deliveryPrice = 0;
            } else {
                deliveryPrice = parseFloat(chosenDeliveryMethodObj.price);
            }
        }

        return deliveryPrice;
    }

    setBranchDataToMapMarkerFormat = () => {
        const branchData = this.props.branchData;
        if (branchData) {
            return ({
                x: branchData.latitude,
                y: branchData.longitude,
                centerX: branchData.latitude,
                centerY: branchData.longitude,
            })
        }
        return ({
            x: null,
            y: null,
            centerX: 41.7197567,
            centerY: 44.767644499999996,
        });
    }

    onMapClicked = (mapProps, map, clickEvent) => {
        if (!this.props.disabled) {
            // props.mapMarker
            const lat = clickEvent.latLng.lat();
            const lng = clickEvent.latLng.lng();

            Geocode.fromLatLng(lat, lng).then(
                response => {
                    const address = response.results[0].formatted_address;
                    this.setAddressValue(address);
                    this.setMapMarker(lat, lng);
                    this.toggleCurrentLocationError(false);
                },
                error => {
                    console.error(error);
                }
            )

        }
    }

    renderCheckoutFormInner = () => {
        return (
            <div ref={this.checkoutFormRef}>
                <CheckoutForm
                    checkoutConfigs={this.state.checkoutConfigs}

                    deliveryTime={this.state.deliveryTime}
                    showTimePicker={this.state.showTimePicker}
                    showTimePickerHandler={this.showTimePicker}
                    setTime={this.setTime}

                    mapMarker={this.setBranchDataToMapMarkerFormat()}
                    setMapMarker={this.setMapMarker}
                    addressInput={this.props.branchData ? this.props.branchData.address : null}
                    showLocationModal={this.props.showLocationModal}
                    google={this.props.google}
                    onMapClicked={this.onMapClicked}

                    showCalendar={this.showCalendar}
                    serverError={this.state.serverError}
                    cartData={this.props.cartData}
                    inputDate={this.state.inputDate}
                    onInputDateChanged={this.onInputDateChanged}
                    onDeliveryChangedHandler={this.onDeliveryChangedHandler}
                    onTipChangedHandler={this.onTipChangedHandler}
                    onShowPopup={this.showCouponCheckPopup}
                    deliveryOptions={this.state.deliveryOptions}
                    paymentOptions={this.state.paymentOptions}

                    getStr={this.props.getStr}
                    generalData={this.props.generalData}
                    countCartTotalWithCoupon={this.countCartTotalWithCoupon}


                />
            </div>
        )
    }
    renderCheckoutForm = () => {
        if (this.props.cartData?.length === 0) {
            return (
                <ColDisabled className={'order-lg-1'} xs={12} lg={7} xl={8}>
                    {this.renderCheckoutFormInner()}
                </ColDisabled>
            )
        } else if (this.props.cartData?.length > 0) {
            return (
                <Col className={'order-lg-1'} xs={12} lg={7} xl={8}>
                    {this.renderCheckoutFormInner()}
                </Col>
            )
        }
    }

    render() {

        if (!this.props.branchData) {
            //redirect to homepage when no branch is selected
            // this.props.history.replace('/')
        }

        const deliveryPrice = this.getDeliveryPrice();
        const titleBannerImage = this.props.generalData?.website_icon_checkout
            ? imagePrepath(this.props.generalData?.website_icon_checkout)
            : bannerImg;

        if (this.state.deliveryOptions.length === 0 || this.state.paymentOptions.length === 0) {
            if (this.state.paymentAndDeliveryLoaded) {
                return (
                    <ThemeProvider theme={window.THEME_CONFIGx}>
                        <Wrp>
                            <CheckoutNotAvaliable
                                getStr={this.props.getStr}
                                bannerImg={titleBannerImage}
                            />
                        </Wrp>
                    </ThemeProvider>
                )
            }

            return <Loader />
        }

        return (
            <ThemeProvider theme={window.THEME_CONFIG}>
                <CheckoutSuccessWrp ref={this.checkoutSuccessRef}>
                    <div className='bgDarkTransparent'></div>
                    <div className='checkoutSuccessText'>{this.props.getStr('checkout_success')}
                        <i className="fas fa-check-circle ml-2" />
                    </div>
                </CheckoutSuccessWrp>

                <PhoneValidator
                    onSubmitHandler={this.onSubmitHandler}
                    onClosePopup={this.onClosePopup}
                    showPhoneValidatorPopup={this.state.showPhoneValidatorPopup}
                    getStr={this.props.getStr}
                />

                {this.state.showCouponCheckPopup &&
                    <CommonPopup
                        hidePopup={this.hideCouponCheckPopup}
                        successBtnCallback={() => {
                            this.setState({
                                showCouponCheckPopup: false,
                            });
                            this.handleCouponApplyClicked();
                        }}
                        successBtnText={getStrHelper('popup_btn_yes')}
                        cancelBtnCallback={() => {
                            this.setState({
                                coupon: '',
                                showCouponCheckPopup: false
                            });
                            if (this.state.generatedFormValues) {
                                this.onShowPopup(this.state.generatedFormValues);
                            }
                        }}
                        cancelBtnText={getStrHelper('popup_btn_no')}
                        showButtons
                    >
                        <h4 className='text-center mb-3'>{getStrHelper('coupon_title')}</h4>
                        <p className='text-center'>{`${getStrHelper('coupon_text')} "${this.state.coupon}"`}</p>
                    </CommonPopup>
                }

                <Wrp>
                    <TitleBanner imageURL={titleBannerImage} text={this.props.getStr('checkout')} />
                    <Container>
                        <Row>
                            <Col className={'order-lg-2'} xs={12} lg={5} xl={4}>

                                <OrderDetails
                                    onCartItemRemoved={this.props.removeCartItem}
                                    onEditCartItem={this.props.onEditCartItemHandler}
                                    deliveryPrice={deliveryPrice}
                                    cartData={this.props.cartData}
                                    getStr={this.props.getStr}

                                    additionalFeesInfo={this.state.additionalFeesInfo}

                                    tip={this.state.tip}

                                    handleCouponChange={this.handleCouponChange}
                                    handleCouponApplyClicked={this.handleCouponApplyClicked}
                                    handleCouponRemoveClicked={this.handleCouponRemoveClicked}
                                    coupon={this.state.coupon}
                                    couponApplied={this.state.couponApplied}
                                    couponData={this.props.couponData}
                                />
                            </Col>

                            {this.renderCheckoutForm()}

                        </Row>
                    </Container>
                </Wrp>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    cartData: state.cart.cart,
    couponData: state.cart.couponData,
    generalData: state.app.generalData,
    branchData: state.app.branchData,
});

const mapDispatchToProps = dispatch => ({
    onFormSubmit: (dataObj, verifCode, isScheduled, marker) => dispatch(checkoutFormSubmit(dataObj, verifCode, isScheduled, marker)),
    sendVerifCode: (phone) => dispatch(sendVerifCode(phone)),
    clearCart: () => dispatch(clearCart()),
    showLocationModal: (showModalCartWarning) => dispatch(showLocationModal(showModalCartWarning)),
    setCouponData: (coupon) => dispatch(setCouponData(coupon)),
    removeCoupon: () => dispatch(removeCoupon()),
    removeCartItem: (index) => dispatch(CartAC.removeCartItem(index))
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStr,
    withPopup,
    GoogleApiWrapper({
        apiKey: 'AIzaSyCtukSVhWDD4J8U6UGWIuRkSDS24psOkak'
    })
)(Checkout)

const ColDisabled = styled(Col)`
    cursor: no-drop;
    opacity: 0.5;
    input, button, textarea, div {
        pointer-events: none;
    }
`;

const CheckoutSuccessWrp = styled.div`
position: fixed;
width: 100%;
height: 100%;
z-index: 99999;
top: 0;
left: 0;

display: flex;
justify-content: center;
align-items: center;

.bgDarkTransparent{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
}

.checkoutSuccessText{
    position: relative;
    padding: 20px 30px;
    font-size: 17px;
    background: white;
    border-radius: 10px;
}

.checkoutSuccessText i {
    color: #000;
    font-size: 22px;
}
`;

const Wrp = styled.div`
background: transparent;
padding-bottom: 150px;
.text{
    color: ${props => props.theme.text.colors.primary};
}
`;