import CallAPI from "restaurants_apimanager";

const apiHandler = (reqName, data = {}) => {
   if(data) {
      data.Languageid = window.store.getState().app.langId;
   }
   
   return CallAPI(reqName, data)
      .then(response => {
         if (response) {
            return response.json()
         }
      })
      .catch(response => {
         try {
            const data = response.json();
            return data;
         } catch(err) {
            console.error(err);
         }
      })
}

export const getProduct = (id) => {
   return apiHandler('getProduct', {
      Productid: id,
   })
}

export default apiHandler;